import { RecordRoles } from 'data/enums';
import { ArrayResponseType, Record, RecordRole } from 'data/types';
import { useMemo } from 'react';

const typeOrder = {
  [RecordRoles.CLIENT]: 0,
  [RecordRoles.AFFILIATE]: 1,
  [RecordRoles.OWNER]: 2,
  [RecordRoles.COLLABORATOR]: 2,
  [RecordRoles.VIEWER]: 2,
};

const sortUsersByType = (
  users: (RecordRole | { type: RecordRoles; user: { firstName?: string; lastName?: string } })[],
) => users.sort((a, b) => typeOrder[a.type] - typeOrder[b.type]);

export const useRecordUsersWithoutClient = (affiliates?: ArrayResponseType<RecordRole>, record?: Record) => {
  const usersWithClient = useMemo(() => {
    if (affiliates?.items) {
      return affiliates?.items?.some((affiliate) => affiliate.type === RecordRoles.CLIENT)
        ? affiliates.items
        : [
            ...affiliates.items,
            { type: RecordRoles.CLIENT, user: { firstName: record?.firstName, lastName: record?.lastName } },
          ];
    }
    return [];
  }, [affiliates, record]);

  return sortUsersByType(usersWithClient);
};
