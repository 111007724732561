import { api } from 'data';
import {
  ArrayResponseType,
  EditRecordFields,
  DocumentFile,
  Record,
  Client,
  DocumentLinkFields,
  EditRecordStatus,
  BasicRecord,
  RecordIntake,
} from 'data/types';

export const fetchRecords = (userId: string, params: string, isClient?: boolean): Promise<ArrayResponseType<Record>> =>
  api.get(`/records/${isClient ? 'patients' : 'therapists'}/${userId}?${params}`);

export const fetchBasicRecords = (userId: string, params: string): Promise<ArrayResponseType<BasicRecord>> =>
  api.get(`/records/users/${userId}/basic?${params}`);

export const fetchOrganisationRecords = (organisationId: string, params: string): Promise<ArrayResponseType<Record>> =>
  api.get(`/records/organisation/${organisationId}?${params}`);

export const fetchRecord = ({ recordId, isAffiliate }: { recordId?: string; isAffiliate?: boolean }): Promise<Record> =>
  api.get(`/records/${recordId}${isAffiliate ? '/affiliates' : ''}`);

export const fetchRecordDocuments = (
  recordId: string,
  userId: string,
  params: string,
): Promise<ArrayResponseType<DocumentFile>> => api.get(`/records/${recordId}/users/${userId}/files?${params}`);

export const linkDocumentToRecord = (recordId: string, userId: string, body: DocumentLinkFields): Promise<Record> =>
  api.post(`/records/${recordId}/users/${userId}/files`, body);

export const editRecord = (recordId: string, fields: EditRecordFields): Promise<Record> =>
  api.patch(`/records/${recordId}`, fields);

export const editRecordStatus = (recordId: string, fields: EditRecordStatus): Promise<Record> =>
  api.patch(`/records/${recordId}/status`, fields);

export const deleteRecord = (recordId: string): Promise<unknown> => api.delete(`/records/${recordId}`);

export const createRecord = (therapistId: string, body: Partial<Client>): Promise<Record> =>
  api.post(`/records/therapists/${therapistId}`, body);

export const fetchRecordRegistrations = (recordId: string, query?: string): Promise<ArrayResponseType<RecordIntake>> =>
  api.get(`/intakes/records/${recordId}?${query}`);
