import { trajectoryKeys, recordKeys } from 'data/utils/hookKeys';
import { useMutation, UseMutationResult, useQuery, useQueryClient, UseQueryResult } from '@tanstack/react-query';
import { Trajectory, ArrayResponseType, TrajectoryEntity, CreateTrajectory, EditTrajectory } from 'data/types';

import queryString from 'qs';
import { SortDirection } from 'data/enums';

import * as api from '../actions-query';
import { AxiosError } from 'axios';

interface TrajectoriesSearchParams {
  pastTrajectories?: number;
  limit?: number;
  offset?: number;
}

export const activeTrajectoriesFetchParams: TrajectoriesSearchParams = { pastTrajectories: 0, limit: 0, offset: 0 };
export const endedTrajectoriesFetchParams: TrajectoriesSearchParams = { pastTrajectories: 1, limit: 0, offset: 0 };

export const useTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>(trajectoryKeys.clientAll(recordId), () =>
    api.fetchRecordClientTrajectories(recordId),
  );

export const usePastTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>(trajectoryKeys.clientPast(recordId), () =>
    api.fetchRecordClientPastTrajectories(recordId, queryString.stringify({ pastTrajectories: 1 })),
  );

export const useAffiliateTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>(trajectoryKeys.clientAll(recordId), () =>
    api.fetchAffiliateRecordTrajectories(recordId),
  );

export const usePastAffiliateTrajectories = (recordId: string) =>
  useQuery<Omit<ArrayResponseType<Trajectory>, 'total'>, AxiosError>(trajectoryKeys.clientPast(recordId), () =>
    api.fetchAffiliateRecordPastTrajectories(recordId, queryString.stringify({ pastTrajectories: 1 })),
  );

export const useRecordTrajectories = (recordId: string, searchParams?: TrajectoriesSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<Trajectory>, AxiosError>(trajectoryKeys.recordAll(recordId, query), () =>
    api.fetchRecordTrajectories(recordId, query),
  );
};

export const useCreateTrajectory = (): UseMutationResult<
  TrajectoryEntity,
  AxiosError,
  { recordId: string; values: CreateTrajectory },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ recordId, values }) => api.createTrajectory(recordId, values), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(recordKeys.detail(variables.recordId));
    },
  });
};

export const useEditTrajectory = (
  recordId: string,
): UseMutationResult<TrajectoryEntity, AxiosError, { trajectoryId: string; values: EditTrajectory }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ trajectoryId, values }) => api.editTrajectory(trajectoryId, values), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        trajectoryKeys.recordAll(recordId, queryString.stringify(endedTrajectoriesFetchParams)),
      );
      queryClient.invalidateQueries(
        trajectoryKeys.recordAll(recordId, queryString.stringify(activeTrajectoriesFetchParams)),
        {
          refetchType: 'all',
        },
      );
      queryClient.invalidateQueries(trajectoryKeys.clientAll(recordId));
      queryClient.invalidateQueries(trajectoryKeys.detail(variables.trajectoryId), { refetchType: 'all' });
      queryClient.invalidateQueries(recordKeys.detail(recordId));
    },
  });
};

export const useTrajectory = (id?: string) =>
  useQuery<Trajectory, AxiosError>(trajectoryKeys.detail(id), () => api.fetchTrajectory(id), {
    enabled: !!id,
    refetchOnMount: false,
  });

export const useTrajectoryClient = (id: string) =>
  useQuery<Trajectory, AxiosError>(trajectoryKeys.detail(id), () => api.fetchTrajectoryClient(id));

export const useAllRecordTrajectories = (
  recordId: string,
  params: { sort: { [key in string]?: SortDirection } },
): UseQueryResult<ArrayResponseType<Trajectory>, AxiosError> =>
  useQuery<ArrayResponseType<Trajectory>, AxiosError>(trajectoryKeys.clientAll(recordId), () =>
    api.fetchAllRecordTrajectories(recordId, queryString.stringify(params)),
  );
