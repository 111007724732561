import React from 'react';
import { PUIText } from 'ui';
import s from './styles.module.scss';

const UnsupportedBrowserPage = () => (
  <div className={s.textContainer}>
    <PUIText className={s.infoText}>
      Je huidige internet browser is verouderd en wordt niet ondersteund door QIT online.{' '}
    </PUIText>
    <PUIText>
      Gelieve een moderner browser te gebruiken. Wij raden de nieuwste versie van Google Chrome aan:{' '}
      <a className={s.linkText} href="https://www.google.com/chrome/">
        Chrome browser
      </a>
    </PUIText>
  </div>
);

export default UnsupportedBrowserPage;
