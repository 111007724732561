import { api } from 'data';
import { setStoredToken } from 'data/utils/tokenStorage';

export const patchConfirmNewEmailToken = (token: string): Promise<{ token: string }> =>
  api.patch(`/tokens/${token}/email`);

export const resetPassword = (email: string): Promise<void> => api.post(`/users/password-reset`, { email });

export const logout = (refreshToken: string): Promise<void> => api.post('/logout', { refreshToken });

export const confirmPassword = (token: string, values: { password: string; repeat: string }): Promise<void> =>
  api.patch(`/users/password-reset/${token}`, values);

export const removeAuthorizationHeaders = () => {
  delete api.defaults.headers.common['authorization'];
};

export const setAuthorization = (access: string) => {
  setStoredToken(access);
};
