import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import theme from 'ui/utils/theme';
import { SessionStatusEnum } from 'app/constants/app';
import { PUIText } from 'ui/components/text-pui';
import { Icon } from 'ui/components/icon';
import { isSameDay } from '../../../utils/actions';
import s from './styles.module.scss';
import { sortByNumericProperty } from 'app/utils/helpers/sortByNumericProperty';

const QuestionChips = ({
  sessions,
  children,
  questionnaire,
  isFinished,
  isPast,
  isSessionSent,
  sessionClockStatus,
  session,
}) => {
  const [checkedStatus, setCheckedStatus] = useState(true);

  const lastSession = sessions ? sortByNumericProperty(sessions, 'orderIndex')[-1] : null;

  const isUnanswered = lastSession && session ? !isSameDay(session.startDate, lastSession.startDate) : null;

  const completed = questionnaire.status === 3;
  let chipsStatus = 'chipsStatusDefault';
  (() => {
    if (completed) chipsStatus = 'chipsStatusFinished';
    else if (isFinished) chipsStatus = 'chipsSessionStatusFinished';
    else if (isSessionSent && isPast && isUnanswered) chipsStatus = 'chipsStatusUnanswered';
    else if (isSessionSent) chipsStatus = 'chipsStatusInProgress';
  })();

  const skipped = session ? session.status === SessionStatusEnum.skipped : null;

  const renderedIcon = () => {
    if (completed) return <Icon icon="check" size="sm" />;
    if (skipped) return <Icon icon="calendar" size="sm" />;

    if (isFinished) return <Icon icon="alertTriangle" size="sm" />;
    if (isSessionSent && isPast && isUnanswered) return <Icon icon="remove" size="sm" />;

    if (isSessionSent && sessionClockStatus) return <Icon icon="clock" size="sm" />;
    return <Icon icon="calendar" size="sm" />;
  };

  useEffect(() => {
    setCheckedStatus(questionnaire.checked);
  });

  return typeof checkedStatus === 'undefined' || checkedStatus ? (
    <div className={cx(s.chips, s[chipsStatus])}>
      <PUIText className={s.chipsName} color={theme.colors.base900} size={theme.typography.sizes.xs}>
        {children}
      </PUIText>
      {renderedIcon()}
    </div>
  ) : null;
};

QuestionChips.propTypes = {
  children: PropTypes.node.isRequired,
  questionnaire: PropTypes.bool,
  isFinished: PropTypes.bool,
  isSessionSent: PropTypes.bool,
  sessionClockStatus: PropTypes.bool,
};

QuestionChips.defaultProps = {
  questionnaire: false,
  isFinished: false,
  isSessionSent: false,
  sessionClockStatus: false,
};

export { QuestionChips };
