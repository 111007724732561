export enum EventStatuses {
  planned = 'planned',
  sent = 'sent',
  to_fill_in = 'to_fill_in',
  in_progress = 'in_progress',
  complete = 'complete',
  ended = 'ended',
  no_show = 'no_show',
  cancelled = 'cancelled',
}
