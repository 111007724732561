import { pixelId } from 'app/constants/app';
import ReactPixel from 'react-facebook-pixel';
import { PROD } from 'app/constants/env';

export const init = () => {
  if (PROD) {
    ReactPixel.init(pixelId, undefined, {
      autoConfig: true,
      debug: false,
    });
  }
};
