import { CalculationType } from 'data/enums';
import { GraphItem } from 'data/types';

export const data: GraphItem = {
  id: '2a5af563-f00c-11e9-b29e-0242ac120002',
  createdAt: '2019-10-16T11:57:45+00:00',
  dots: [
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac120002',
      createdAt: '',
      label: 'Dot from calculation',
      y: 4,
      value: 7,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac120002',
      createdAt: '',
      label: 'Dot from calculation',
      y: 4,
      value: 3,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac120002',
      createdAt: '',
      label: 'Dot from calculation',
      y: 4,
      value: 5,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac120002',
      createdAt: '',
      label: 'Dot from calculation',
      y: 4,
      value: 15,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac120002',
      createdAt: '',
      label: 'Dot from calculation',
      y: 4,
      value: 11,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac120002',
      createdAt: '',
      label: 'Dot from calculation',
      y: 4,
      value: 8,
      size: 1,
    },
  ],
  graph: {
    id: 'c218036d-f00b-11e9-b29e-0242ac120002',
    index: 0,
    orderIndex: 0,
    createdAt: '',
    backgroundNorms: [
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 1',
        id: '97943509-f00b-11e9-b29e-0242ac120002',
        upperLimit: 3.0,
        textColor: '#f44336',
        backgroundColor: '#02fa1f',
        graphBackgroundColor: '#02fa1f',
        text: 'Text definition 1',
      },
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 2',
        id: '97943e3c-f00b-11e9-b29e-0242ac020002',
        upperLimit: 7.0,
        textColor: '#fac02d',
        backgroundColor: '#f6fa02',
        graphBackgroundColor: '#f6fa02',
        text: 'Text definition 2',
      },
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 3',
        id: '979446c5-f00b-11e9-b29e-0242ac120002',
        upperLimit: 12.0,
        textColor: '#ffcdd2',
        backgroundColor: '#fa9b02',
        graphBackgroundColor: '#fa9b02',
        text: 'Text definition 3',
      },
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 4',
        id: '979446c5-f00b-11e9-b29e-0242ac120002',
        upperLimit: 14.0,
        textColor: '#ffcdd2',
        backgroundColor: '#fa0202',
        graphBackgroundColor: '#fa0202',
        text: 'Text definition 4',
      },
    ],
    type: {
      id: 'scores_graph',
      createdAt: '',
      name: 'scores_graph',
    },
    calculations: [
      {
        id: '59b0c8ac-f00b-11e9-b29e-0242ac120002',
        createdAt: '',
        name: 'Calculation',
        type: CalculationType.MEDIAN,
      },
    ],
    questions: [1.0, 2.0, 3.0, 4.0, 5.0],

    intervalScale: '3',
    minScale: '0',
    maxScale: '14 ',
    titleAbove: 'Title above the graph',
    viewX: true,
    viewY: false,
    xLabel: 'Calculation',
    yLabel: 'Representation',
  },
  type: 'evolution_graph',
};
