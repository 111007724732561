import React from 'react';
import { withIcons, IconProps } from '@panenco/ui';

import * as smCustomIcons from './icons/sm';
import * as mdCustomIcons from './icons/md';
import * as lgCustomIcons from './icons/lg';

export const iconsQIT = { sm: smCustomIcons, md: mdCustomIcons, lg: lgCustomIcons };

const IconComponent = withIcons<typeof iconsQIT>(iconsQIT);

export type IconsType = IconProps<typeof iconsQIT>;

export const Icon = (props: IconsType) => <IconComponent {...props} />;
