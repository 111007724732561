import React from 'react';
import PropTypes from 'prop-types';

import FormControl from './control';

class FormField extends React.Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.any,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
    }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func,
      setFieldTouched: PropTypes.func,
      touched: PropTypes.object,
      errors: PropTypes.object,
    }).isRequired,
    onBlurAdapter: PropTypes.func,
    valueAdapter: PropTypes.func,
    onChangeAdapter: PropTypes.func,
  };

  static defaultProps = {
    onBlurAdapter: null,
    onChangeAdapter: null,
    valueAdapter: (value) => value,
  };

  onChange = (...args) => {
    const { form, field, onChangeAdapter, onChangeAction } = this.props;
    form.setFieldValue(field.name, onChangeAdapter(...args));

    if (onChangeAction) onChangeAction();
  };

  onBlur = (...args) => {
    const { form, field, onBlurAdapter } = this.props;
    form.setFieldTouched(field.name, onBlurAdapter(...args));
  };

  extractFieldProps = () => {
    const {
      field,
      form: { errors, setFieldValue },
      onChangeAdapter,
      onBlurAdapter,
      valueAdapter,
      ...props
    } = this.props;

    let error = null;
    const isEmailInput = field.name === 'email';

    if (errors[field.name]) error = errors[field.name];

    if (this.props.errors) error = this.props.errors;

    const fieldProps = {
      ...field,
      ...props,
      error,
      value: valueAdapter(field.value),
      setFieldValue,
    };

    if (isEmailInput) {
      fieldProps.onChange = (e) => {
        setFieldValue(field.name, e.target.value?.trim());
        if (this.props.onChange) {
          this.props.onChange(e);
        }
      };
    }

    if (onChangeAdapter) {
      fieldProps.onChange = this.onChange;
    }

    if (onBlurAdapter) {
      fieldProps.onBlur = this.onBlur;
    }
    return fieldProps;
  };

  render() {
    return <FormControl {...this.extractFieldProps()} />;
  }
}

export default FormField;
