import { AnalyticsEvent } from 'data/enums';

type PassedEvent = {
  event: AnalyticsEvent;
  [key: string]: unknown;
};

declare global {
  interface Window {
    dataLayer?: PassedEvent[];
  }
}

export const useGTMEvents = () => {
  const pushToDataLayer = (data: PassedEvent): void => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push(data);
  };

  return {
    events: AnalyticsEvent,
    trackEvent: (event: AnalyticsEvent, data?: Record<string, unknown>) => {
      pushToDataLayer({
        event,
        ...data,
      });
    },
  };
};
