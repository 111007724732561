import React from 'react';
import { TabContext, Tablist } from '@panenco/ui';
import PropTypes from 'prop-types';
import cx from 'classnames';
import s from './styles.module.scss';

const Sidebar = ({ className, children }) => (
  <TabContext>
    <Tablist className={cx(s.sidebar, className)} data-intercom-target="sidebar-id">
      {children}
    </Tablist>
  </TabContext>
);

Sidebar.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Sidebar.defaultProps = {
  className: null,
};

export { Sidebar };
