export enum EventTypeEnum {
  module_task_activity = 'module_task_activity',
  simple_task_activity = 'simple_task_activity',
  interactionVideo_interaction_activity = 'interactionVideo_interaction_activity',
  interactionPhysical_interaction_activity = 'interactionPhysical_interaction_activity',
  session = 'session',
  session_activity = 'session_activity',
  interactionSync_interaction_activity = 'interactionSync_interaction_activity',
  custom = 'simple',
  note = 'note',
}
