import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import colors from 'ui/styles/_colors.module.scss';
import sizes from 'ui/styles/sizes.module.scss';
import weights from 'ui/styles/_weights.module.scss';
import s from './styles.module.scss';

const Text = ({ color, size, weight, className, children, style, multiline, ...props }) => (
  <span className={cx(s.text, color, size, weight, className, multiline && s.textMultiline)} style={style} {...props}>
    {children}
  </span>
);

Text.color = Object.entries(colors).reduce((obj, [colorName]) => {
  // eslint-disable-next-line
  obj[colorName] = s[`textColor__${colorName}`];
  return obj;
}, {});

Text.size = Object.entries(sizes).reduce((obj, [sizeName]) => {
  // eslint-disable-next-line
  obj[sizeName] = s[`textSize__${sizeName}`];
  return obj;
}, {});

Text.weight = Object.entries(weights).reduce((obj, [weightName]) => {
  // eslint-disable-next-line
  obj[weightName] = s[`textWeight__${weightName}`];
  return obj;
}, {});

Text.propTypes = {
  style: PropTypes.objectOf(PropTypes.object),
  color: PropTypes.oneOf(Object.values(Text.color)),
  weight: PropTypes.oneOf(Object.values(Text.weight)),
  size: PropTypes.oneOf(Object.values(Text.size)),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  multiline: PropTypes.bool,
};

Text.defaultProps = {
  style: null,
  weight: Text.weight.regular,
  size: Text.weight.s,
  color: s.textColor,
  className: null,
  id: null,
  multiline: false,
};

export { Text };
