import { AnalyticsEvent } from 'data/enums';
import { useIntercom } from 'react-use-intercom';

export const useIntercomEvents = () => {
  const { trackEvent } = useIntercom();
  return {
    intercomEvents: AnalyticsEvent,
    trackIntercomEvent: (event: AnalyticsEvent, data?: Record<string, unknown>): void => {
      trackEvent(event, data);
    },
  };
};
