import { createTheme } from '@mui/material/styles';

export interface TextSize {
  fontSize: string;
  lineHeight: string;
}

declare module '@mui/material/styles' {
  interface Palette {
    alert: { main: string };
    neutral: Palette['primary'];
  }

  interface PaletteOptions {
    alert: { main?: string };
    dark: PaletteOptions['primary'];
  }

  interface PaletteColor {
    neutral?: string;
  }

  interface SimplePaletteColorOptions {
    neutral?: string;
  }

  interface TypographyVariants {
    xxs: TextSize;
    xs: TextSize;
    s: TextSize;
    m: TextSize;
    l: TextSize;
    xl: TextSize;
    xxl: TextSize;
  }

  interface TypographyVariantsOptions {
    xxs?: TextSize;
    xs?: TextSize;
    s?: TextSize;
    m?: TextSize;
    l?: TextSize;
    xl?: TextSize;
    xxl?: TextSize;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xxs: true;
    xs: true;
    s: true;
    m: true;
    l: true;
    xl: true;
    xxl: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material/Tab' {
  interface TabPropsVariantOverrides {
    subtitle: true;
  }
}

export const themeMUI = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 840,
      lg: 1120,
      xl: 1441,
    },
  },
  palette: {
    primary: {
      main: '#d41f15',
      dark: '#9a251e',
      light: '#ef534a',
      neutral: '#ffeeed',
    },
    secondary: {
      main: '#0a7ea5',
      dark: '#045b78',
      light: '#0692c1',
      neutral: '#eff4f6',
    },
    grey: {
      100: '#fff',
      200: '#f8fafb',
      600: '#e9ebec',
      700: '#bdc8cc',
      800: '#767676',
      900: '#151a1c',
    },
    dark: {
      main: '#767676',
    },
    success: { main: '#57d9a3' },
    error: { main: '#ff431f' },
    alert: { main: '#ff991f' },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontSize: '20px',
      lineHeight: '140%',
    },
    h2: {
      fontSize: '20px',
      lineHeight: '140%',
    },
    h3: {
      fontSize: '20px',
      lineHeight: '140%',
    },
    xxs: {
      fontSize: '10px',
      lineHeight: '130%',
    },
    xs: {
      fontSize: '12px',
      lineHeight: '130%',
    },
    s: {
      fontSize: '14px',
      lineHeight: '130%',
    },
    m: {
      fontSize: '16px',
      lineHeight: '130%',
    },
    l: {
      fontSize: '18px',
      lineHeight: '130%',
    },
    xl: {
      fontSize: '26px',
      lineHeight: '130%',
    },
    xxl: {
      fontSize: '28px',
      lineHeight: '130%',
    },
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          textTransform: 'none',
          width: '120px',
          height: '80px',
          minHeight: 0,
          border: `1px solid ${themeMUI.palette.grey[600]}`,
          background: themeMUI.palette.secondary.neutral,
          fontWeight: themeMUI.typography.fontWeightRegular,
          fontSize: themeMUI.typography.m.fontSize,
          ':first-of-type': {
            borderRadius: '0',
          },
          ':last-of-type': {
            borderRadius: '0',
          },
          '&.Mui-selected': {
            background: themeMUI.palette.grey[100],
            color: themeMUI.palette.secondary.main,
            fontWeight: themeMUI.typography.fontWeightBold,
            borderBottom: `1px solid ${themeMUI.palette.grey[100]}`,
          },
          '&:hover': {
            color: themeMUI.palette.secondary.light,
            '> .MuiTab-iconWrapper': {
              color: themeMUI.palette.secondary.light,
            },
          },
          '> .MuiTab-iconWrapper': {
            color: themeMUI.palette.secondary.main,
          },

          ...(ownerState.variant === 'subtitle' && {
            borderColor: 'transparent',
            borderStyle: 'solid',
            borderWidth: '0 1px',
            borderRadius: '100px',
            color: themeMUI.palette.secondary.main,
            fontWeight: 600,
            height: '36px',
            padding: '10px 16px !important',
            width: 'fit-content',
            '&:first-of-type': {
              borderRadius: '100px',
              borderLeftColor: 'transparent',
              '&:hover': {
                borderRadius: 0,
              },
            },
            '&:last-of-type': {
              borderRadius: '100px',
              borderRightColor: 'transparent',
              '&:hover': {
                borderRadius: 0,
              },
            },
            '&:hover': {
              borderRadius: 0,
              borderLeftColor: themeMUI.palette.grey[700],
              borderRightColor: themeMUI.palette.grey[700],
            },
            '&.Mui-selected': {
              background: themeMUI.palette.secondary.main,
              color: themeMUI.palette.grey[100],
              '&:hover': {
                borderColor: 'transparent',
                borderRadius: '100px !important',
              },
            },
          }),
        }),
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          border: 'none',
          padding: '0px 4px',
        }),
        indicator: () => ({
          display: 'none',
          transition: 'none',
        }),
        scroller: () => ({
          display: 'flex',
          alignItems: 'center',
        }),
        flexContainer: () => ({
          width: '100%',
        }),
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: () => ({
          textTransform: 'none',
          fontWeight: themeMUI.typography.fontWeightRegular,
          padding: '8px 0 8px 0 !important',

          '&.MuiBottomNavigationAction-root.Mui-selected': {
            color: themeMUI.palette.secondary.main,
          },

          '&.Mui-selected': {
            background: themeMUI.palette.grey[100],

            '.MuiBottomNavigationAction-label': {
              color: themeMUI.palette.secondary.main,
              fontSize: themeMUI.typography.xxs.fontSize,
              fontWeight: themeMUI.typography.fontWeightBold,
            },
          },

          '.MuiBottomNavigationAction-label': {
            opacity: '1',
            marginTop: '4px',
            fontSize: themeMUI.typography.xxs.fontSize,
          },
        }),
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: () => ({
          height: '48px',
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme, ownerState }) => ({
          fontWeight: theme.typography.fontWeightBold,
          textTransform: 'none',

          ...(ownerState.variant === 'outlined' && {
            border: `2px solid ${theme.palette.grey[800]}`,
            padding: '10px 34px !important',
            fontSize: themeMUI.typography.m.fontSize,

            '&:hover': {
              backgroundColor: theme.palette.grey[800],
              border: `2px solid ${theme.palette.grey[800]}`,
              color: theme.palette.grey[100],
            },
          }),
        }),
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: () => ({ padding: '8px' }),
        list: () => ({ padding: 0, maxHeight: 200 }),
      },
    },
  },
});
