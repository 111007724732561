import { UseMutationResult, UseQueryResult, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  Intake,
  OrganisationForm,
  ArrayResponseType,
  CreateOrganisationForm,
  IntakeFormQuestion,
  CreateIntake,
  CreateIntakeFormQuestion,
  IntakePublicFormQuestions,
  IntakeCSVData,
  IntakeAnswer,
  DocumentFile,
} from 'data/types';
import { intakesKeys, organisationFoldersKeys } from 'data/utils/hookKeys';
import queryString from 'qs';
import { IntakesTableAccessor, IntakeStatus, ReferenceQuestionType, SortDirection } from 'data/enums';
import { defaultIntakesSort } from 'app/components/app/therapist/intakes/constants';
import saveBlob from 'data/utils/actions';
import { fiveMinutesInMilliseconds } from 'app/constants/app';
import { useIntakeAccess } from 'app/utils/hooks';

import * as api from '../actions-query';

const defaultIntakesParams = {
  sort: {
    [defaultIntakesSort.sort]: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
};

export interface IntakesParams {
  sort: {
    [key in IntakesTableAccessor]?: SortDirection;
  };
  limit?: number;
  offset?: number;
  filters?: {
    search?: string | null;
    status?: string;
    createdAtFrom?: string;
    createdAtTo?: string;
  };
}

export const useIntakes = (organisationId: string, params?: IntakesParams) => {
  const query = queryString.stringify(params);
  return useQuery<ArrayResponseType<Intake>, AxiosError>(
    intakesKeys.all(query),
    () => api.fetchIntakes(organisationId, query),
    {
      enabled: !!organisationId,
    },
  );
};

export const useOrganisationForm = (organisationId: string) =>
  useQuery<OrganisationForm, AxiosError>(
    intakesKeys.organisationForm(organisationId),
    () => api.fetchOrganisationForm(organisationId),
    { enabled: !!organisationId },
  );

export const useOrganisationFormQuestions = (organisationId: string, formId: string) =>
  useQuery<ArrayResponseType<IntakeFormQuestion>, AxiosError>(
    intakesKeys.organisationFormQuestions(organisationId, formId),
    () => api.fetchOrgsanisationFormQuestions(formId),
    { enabled: !!formId },
  );

export const usePublicFormQuestions = (token: string) =>
  useQuery<IntakePublicFormQuestions, AxiosError>(
    intakesKeys.publicFormQuestions(token),
    () => api.fetchPublicFormQuestions(token),
    { enabled: !!token },
  );

export const useCreateOrganisationForm = (): UseMutationResult<
  OrganisationForm,
  AxiosError,
  { organisationId: string; body: CreateOrganisationForm },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ organisationId, body }) => api.createOrganisationForm(organisationId, body), {
    onSuccess: (_, { organisationId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationForm(organisationId));
    },
  });
};

export const useCreateOrganisationFormQuestions = (
  organisationId: string,
): UseMutationResult<
  ArrayResponseType<IntakeFormQuestion>,
  AxiosError,
  { formId: string; body: { questions: CreateIntakeFormQuestion[] } },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ formId, body }) => api.createOrganisationFormQuestions(formId, body), {
    onSuccess: (_, { formId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationFormQuestions(organisationId, formId));
    },
  });
};

export const useDeleteOrganisationFormQuestions = (
  organisationId: string,
): UseMutationResult<void, AxiosError, { formId: string; body: { ids: string[] } }, unknown> => {
  const queryClient = useQueryClient();

  return useMutation(({ formId, body }) => api.deleteOrganisationFormQuestions(formId, body), {
    onSuccess: (_, { formId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationFormQuestions(organisationId, formId));
    },
  });
};

export const useUpdateOrganisationFormQuestions = (
  organisationId: string,
): UseMutationResult<
  ArrayResponseType<IntakeFormQuestion>,
  AxiosError,
  { formId: string; body: { questions: CreateIntakeFormQuestion[] } },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ formId, body }) => api.updateOrganisationFormQuestions(formId, body), {
    onSuccess: (_, { formId }) => {
      queryClient.invalidateQueries(intakesKeys.organisationFormQuestions(organisationId, formId));
    },
  });
};

export const useAnswerIntakeFormPreview = (
  organisationId: string,
): UseMutationResult<Intake, AxiosError, CreateIntake, unknown> => {
  const queryClient = useQueryClient();

  return useMutation((body) => api.answerIntakeFormPreview(organisationId, body), {
    onSuccess: () => {
      queryClient.invalidateQueries(intakesKeys.all());
      queryClient.invalidateQueries(intakesKeys.notification(organisationId));
    },
  });
};

export const useAnswerPublicIntakeForm = (
  token: string,
): UseMutationResult<
  Omit<Intake, 'orderIndex' | 'status' | 'referredTo' | 'deletedAt' | 'record' | 'teams'>,
  AxiosError,
  CreateIntake,
  unknown
> => useMutation((body) => api.answerPublicIntakeForm(token, body));

export const useIntake = (intakeId: string) =>
  useQuery<Intake, AxiosError>(intakesKeys.details(intakeId), () => api.fetchIntake(intakeId), {
    enabled: !!intakeId,
  });

export const useEditIntake = (props: {
  organisationId?: string;
  params?: IntakesParams;
}): UseMutationResult<
  Intake,
  AxiosError,
  {
    intakeId: string;
    body: unknown;
    onSuccess?: () => void;
    folderToValidate?: string;
    shouldInvalidate?: boolean;
    shouldValidateFolder?: boolean;
  },
  unknown
> => {
  const queryClient = useQueryClient();

  return useMutation(({ intakeId, body }) => api.editIntake(intakeId, body), {
    onMutate: async ({ intakeId, body, shouldValidateFolder = false }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: intakesKeys.details(intakeId) });

      // Snapshot the previous value
      const previousIntake = queryClient.getQueryData(intakesKeys.details(intakeId));

      // Update the folders in the intakes list
      if (intakesKeys.all(queryString.stringify(props?.params)) && props?.organisationId && shouldValidateFolder) {
        const availableFolders =
          queryClient.getQueryData(organisationFoldersKeys.list(props.organisationId || ''))?.items || [];
        const folderToUpdate = body?.folderIds.map((folderId) =>
          availableFolders.find((folder) => folder.id === folderId),
        );

        if (intakesKeys.all(queryString.stringify(props?.params))) {
          queryClient.setQueryData(intakesKeys.all(queryString.stringify(props?.params)), (old) => ({
            ...old,
            items: old.items.map((intake) =>
              intake.id === intakeId ? { ...intake, folders: folderToUpdate || intake?.folders } : intake,
            ),
          }));
        }
      }

      // Optimistically update to the new value
      if (previousIntake) {
        queryClient.setQueryData(intakesKeys.details(intakeId), (old) => {
          const transformedBody = {
            ...body,
            teams: body?.assignees?.usersIds?.length
              ? [...old?.teams, { teamUsers: [{ user: { id: body?.assignees?.userIds[0] } }] }]
              : old?.teams,
            assignees: body?.folderIds?.length ? { folders: body?.folderIds } : old?.assignees,
          };
          return { ...old, ...transformedBody };
        });
      }

      // Return a context object with the snapshotted value
      return { previousIntake };
    },
    onSuccess: (_, { onSuccess }) => onSuccess && onSuccess(),
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, { intakeId }, context) => {
      queryClient.setQueryData(intakesKeys.details(intakeId), context?.previousIntake);
    },
    // Always refetch after error or success:
    onSettled: (data, err, { intakeId, folderToValidate, shouldInvalidate = true }) => {
      const query = queryString.stringify(props?.params);
      if (shouldInvalidate) {
        queryClient.invalidateQueries({ queryKey: intakesKeys.details(intakeId) });
        queryClient.invalidateQueries({ queryKey: intakesKeys.timeline(intakeId) });
      }
      queryClient.invalidateQueries({ queryKey: intakesKeys.all(query) });
      queryClient.invalidateQueries({ queryKey: organisationFoldersKeys.detail(folderToValidate) });
      if (props?.organisationId) queryClient.invalidateQueries(intakesKeys.notification(props?.organisationId));
    },
  });
};

export const useDeleteIntake = (
  organisationId?: string,
): UseMutationResult<void, AxiosError, { intakeId: string }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ intakeId }) => api.deleteIntake(intakeId), {
    onSuccess: () => queryClient.invalidateQueries(intakesKeys.notification(organisationId || '')),
  });
};

export const useEditOrganisationForm = (
  orgId: string,
): UseMutationResult<OrganisationForm, AxiosError, { formId: string; body: any }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ formId, body }) => api.editOrganisationForm(formId, body), {
    onSuccess: () => queryClient.invalidateQueries(intakesKeys.organisationForm(orgId)),
  });
};

export const useImportIntakeInBulk = (organisationId: string) => {
  const query = queryString.stringify(defaultIntakesParams);
  const queryClient = useQueryClient();
  return useMutation<ArrayResponseType<Intake>, AxiosError, { items: IntakeCSVData[] }, unknown>(
    (body) => api.importIntakeInBulk(organisationId, body),
    { onSuccess: () => queryClient.invalidateQueries(intakesKeys.all(query)) },
  );
};

export const useReferenceQuestionTypes = () =>
  useQuery<ArrayResponseType<ReferenceQuestionType>, AxiosError>(intakesKeys.referenceTypes(), () =>
    api.fetchReferenceQuestionTypes(),
  );

export const useEditAnswer = (intakeId: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ submissionId, body }: { submissionId: string; body: { answers: IntakeAnswer[] } }) =>
      api.editAnswer(submissionId, body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(intakesKeys.details(intakeId));
      },
    },
  );
};

export const useDownloadIntakeFile = () =>
  useMutation<Blob, AxiosError, { fileId: string; fileName: string }, unknown>(
    ({ fileId }) => api.downloadIntakeFile(fileId),
    {
      onSuccess: (blob, variables) => {
        saveBlob(blob, variables.fileName);
      },
    },
  );

export const useIntakeFileLink = (fileId: string): UseQueryResult<string, AxiosError> =>
  useQuery<string, AxiosError>(intakesKeys.fileLink(fileId), () => api.fetchIntakeFileLink(fileId), {
    enabled: !!fileId,
  });

export const useIntakeFile = (fileId: string): UseQueryResult<DocumentFile, AxiosError> =>
  useQuery<DocumentFile, AxiosError>(intakesKeys.file(fileId), () => api.fetchIntakeFile(fileId), {
    enabled: !!fileId,
  });

export const useDeleteIntakeFile = (intakeId: string) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, Error, { fileId: string }, unknown>(({ fileId }) => api.deleteIntakeFile(fileId), {
    onSuccess: () => {
      queryClient.invalidateQueries(intakesKeys.details(intakeId));
    },
  });
};

export const useIntakesNotification = (organisationId: string) => {
  const { isNoAccess } = useIntakeAccess();
  const query = queryString.stringify({
    filters: { statuses: IntakeStatus.REGISTERED },
  });
  return useQuery<{ count: number }, AxiosError>(
    intakesKeys.notification(organisationId),
    () => api.fetchIntakesNotification(organisationId, query),
    {
      enabled: !!organisationId && !isNoAccess,
      refetchInterval: fiveMinutesInMilliseconds,
      staleTime: fiveMinutesInMilliseconds,
    },
  );
};
