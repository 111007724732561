import React from 'react';
import Logo from '/img/logo-short.svg';
import cx from 'classnames';

import s from './styles.module.scss';

const Loader = ({
  contentOnly,
  fullOpacity,
  text,
}: {
  contentOnly?: boolean;
  fullOpacity?: boolean;
  text?: string;
}) => (
  <div className={cx(s.preloader, contentOnly && s.contentOnly, fullOpacity && s.fullOpacity, text && s.text)}>
    <img alt="loaderLogo" src={Logo} className={s.preloaderLogo} />
    <p>{text}</p>
  </div>
);

export default Loader;
