export enum RoleEnum {
  ROLE_THERAPIST = 'ROLE_THERAPIST',
  ROLE_ORGANISATION_ADMIN = 'ROLE_ORGANISATION_ADMIN',
  ROLE_CLIENT = 'ROLE_PATIENT',
  ROLE_CLIENT_SYSTEM = 'ROLE_PATIENT_SYSTEM',
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
}

export enum TestAccounts {
  THERAPIST_FULL_ORG_1 = 'THERAPIST_FULL_ORG_1',
  THERAPIST_FULL_ORG_2 = 'THERAPIST_FULL_ORG_2',
  CLIENT_FULL_1 = 'CLIENT_FULL_1',
  CLIENT_FULL_2 = 'CLIENT_FULL_2',
  THERAPIST_FULL_CALENDAR_1 = 'THERAPIST_FULL_CALENDAR_1',
}
