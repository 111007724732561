export const getLabel = (label: string) => {
  const words = label.split(/\s+/); // Split the input string into words
  const amount = 15; // Amount of words per line
  const result: string[] = [];

  for (let i = 0; i < words.length; i += amount) {
    result.push(words.slice(i, i + amount).join(' '));
  }

  return result;
};
