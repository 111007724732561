import React from 'react';
import { Text, TextProps } from '@panenco/ui';
import theme from 'ui/utils/theme';

export const PUIText = ({
  children,
  color = theme.colors.base900,
  size = theme.typography.sizes.m,
  multiline,
  ...props
}: TextProps & { multiline?: boolean }) => (
  <Text color={color} size={size} style={multiline ? { whiteSpace: 'pre-wrap' } : {}} {...props}>
    {children}
  </Text>
);
