import { PUIColors, PUISizes, PUIWeights, TextSize } from '@panenco/ui';
import weights from 'ui/styles/_weights.module.scss';

type AppColors = PUIColors & {
  secondary900: string;
  secondary700: string;
  secondary500: string;
  secondary300: string;
  secondary200: string;
};

type AppSizes = PUISizes & {
  xxs: TextSize;
  xxl: TextSize;
};

const colors: AppColors = {
  primary900: '#9a251e',
  primary700: '#d41f15',
  primary500: '#ef534a',
  primary200: '#ffeeed',

  secondary900: '#045b78',
  secondary700: '#0a7ea5',
  secondary500: '#0692c1',
  secondary300: '#c1d6de',
  secondary200: '#eff4f6',

  base100: '#fff',
  base200: '#f8fafb',
  base300: '#eee', //
  base400: '#e0e0e0', //
  base500: '#bdbdbd', //
  base600: '#e9ebec',
  base700: '#bdc8cc',
  base800: '#767676',
  base900: '#151a1c',

  success: '#57d9a3',
  error: '#ff431f',
  alert: '#ff991f',
};

const sizes: AppSizes = {
  xxs: {
    textSize: '10px',
    lineHeight: '130%',
  },
  xs: {
    textSize: '12px',
    lineHeight: '130%',
  },
  s: {
    textSize: '14px',
    lineHeight: '130%',
  },
  m: {
    textSize: '16px',
    lineHeight: '130%',
  },
  l: {
    textSize: '18px',
    lineHeight: '130%',
  },
  xl: {
    textSize: '26px',
    lineHeight: '130%',
  },
  xxl: {
    textSize: '28px',
    lineHeight: '130%',
  },
  h1: {
    textSize: '20px',
    lineHeight: '140%',
  },
  h2: {
    textSize: '20px',
    lineHeight: '140%',
  },
  h3: {
    textSize: '20px',
    lineHeight: '140%',
  },
};

type AppThemeType = {
  colors: AppColors;
  typography: {
    sizes: AppSizes;
    weights: PUIWeights;
  };
};

const appTheme: AppThemeType = {
  colors,
  typography: { sizes, weights },
};

export default appTheme;
export type { AppThemeType };
export { sizes, colors, weights };
