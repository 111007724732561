import { RoleEnum } from 'data/enums';
import { useTokenData } from 'data/hooks/auth';

const rolesIntersection = (allowedRoles: RoleEnum[], userRoles: RoleEnum[]) =>
  allowedRoles.filter((value) => userRoles.includes(value));

export function useHasAccess(allowedRoles: RoleEnum[]): boolean {
  const tokenData = useTokenData();

  const hasAccess = !!tokenData && Boolean(rolesIntersection(allowedRoles, tokenData.roles).length);

  return hasAccess;
}
