import React, { useEffect } from 'react';
import ReactDom from 'react-dom';
import cx from 'classnames';
import theme from 'ui/utils/theme';
import { PUIButtonIcon } from 'ui/components/button-icon-pui';
import { PUIText } from 'ui/components/text-pui';
import PropTypes from 'prop-types';

import { sizeClassesMapping } from './constants.js';
import s from './styles.module.scss';

const PopupNew = ({ className, size = 'sm', children, buttons, title, closeDisabled, closeFunction }) => {
  const handleOverlayClick = (e) => {
    const isOverlay = e.target === e.currentTarget;
    if (!isOverlay || !closeFunction || closeDisabled) return;
    closeFunction();
  };

  useEffect(() => {
    document.onkeydown = (e) => {
      if (closeDisabled || e.key !== 'Escape' || !closeFunction) return;
      closeFunction();
    };

    return () => {
      document.onkeydown = null;
    };
  }, []);

  return ReactDom.createPortal(
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <div onClick={handleOverlayClick} className={s.overlay} role="dialog">
      <div className={cx(s.popup, sizeClassesMapping[size], className)}>
        <div className={s.popupHeader}>
          <PUIText
            size={theme.typography.sizes.l}
            weight={theme.typography.weights.semiBold}
            color={theme.colors.base100}
          >
            {title}
          </PUIText>
          {closeFunction ? (
            <PUIButtonIcon
              onClick={closeFunction}
              className={s.popupHeaderCloseBtn}
              icon="remove"
              disabled={closeDisabled}
            />
          ) : null}
        </div>
        <div className={s.popupBody}>
          {children}
          {buttons && <div className={s.popupButtons}>{buttons}</div>}
        </div>
      </div>
    </div>,
    document.getElementById('portal'),
  );
};

PopupNew.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.node),
  closeFunction: PropTypes.func,
  closeDisabled: PropTypes.bool,
};

PopupNew.defaultProps = {
  className: null,
  title: 'Title',
  buttons: null,
  closeFunction: null,
  closeDisabled: false,
};

export { PopupNew };
