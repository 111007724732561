import {
  ArrayResponseType,
  Organization,
  EditOrganizationFields,
  OrgMember,
  BillingPortalFields,
  CreateOrganizationFields,
  InviteMemberFields,
  IOrganizationAdminDetails,
  IOrganizationStripeSubscriptions,
  IOrganizationStripeLink,
  DocumentFile,
  CreateNewOrganization,
  Addon,
  Products,
  OrganisationPrices,
  OrgTeamMember,
} from 'data/types';
import { api } from 'data';

export const fetchOrganizations = (params: string): Promise<ArrayResponseType<Organization>> =>
  api.get(`/organisations?${params}`);

export const fetchOrganizationMembers = (
  organizationId?: string,
  params?: string,
): Promise<ArrayResponseType<OrgMember>> => api.get(`organisations/${organizationId}/members?${params}`);

export const fetchOrganizationTeams = (
  organizationId?: string,
  params?: string,
): Promise<ArrayResponseType<OrgTeamMember>> => api.get(`organisations/${organizationId}/members/teams?${params}`);

export const fetchOrganizationMembersSimpleList = (
  organizationId?: string,
  params?: string,
): Promise<ArrayResponseType<OrgMember>> => api.get(`organisations/${organizationId}/members/therapist?${params}`);

export const fetchOrganization = (organizationId: string | undefined | null): Promise<Organization> =>
  api.get(`/organisations/${organizationId}`);

export const fetchBillingPortal = (organizationId: string): Promise<BillingPortalFields> =>
  api.get(`payments/organisations/${organizationId}/billing-portal`);

export const createOrganization = (values: CreateOrganizationFields): Promise<Organization> =>
  api.post(`organisations`, values);

export const editOrganization = (organizationId: string, fields: EditOrganizationFields): Promise<Organization> =>
  api.patch(`/organisations/${organizationId}`, fields);

export const deleteOrganization = (organizationId: string): Promise<string> =>
  api.delete(`/organisations/${organizationId}`);

export const addMember = (organizationId: string, fields: InviteMemberFields): Promise<ArrayResponseType<OrgMember>> =>
  api.post(`/organisations/${organizationId}/members/invite`, fields);

export const fetchOrganizationAdminDetails = (
  organizationId: string | undefined | null,
): Promise<IOrganizationAdminDetails> => api.get(`/organisations/${organizationId}/organisation-admin`);

export const fetchOrganizationsStripeSubscriptions = (): Promise<IOrganizationStripeSubscriptions> =>
  api.get('/payments/subscriptions');

export const fetchOrganizationSubscriptionPortalLink = (
  organizationId: string | undefined | null,
  prices: string[],
): Promise<IOrganizationAdminDetails> =>
  api.post(`/payments/organisations/${organizationId}/checkout-portal`, { prices });

export const updateOrganisationSubscription = (organisationId: string, priceId: string): Promise<void> =>
  api.patch(`/payments/organisations/${organisationId}/prices/${priceId}`);

export const fetchOrganisationAddonPrice = (organisationId: string, priceId: string): Promise<any> =>
  api.get(`/payments/organisations/${organisationId}/prices/${priceId}`);

export const fetchOrganizationStripeLink = (
  organizationId: string | undefined | null,
): Promise<IOrganizationStripeLink> => api.get(`/payments/organisations/${organizationId}/customer-dashboard`);

export const fetchOrganizationLogo = (fileId: string, resourceId: string): Promise<DocumentFile> =>
  api.get(`/files/${fileId}/resource/organisation/${resourceId}`);

export const deleteOrganizationLogo = (fileId: string): Promise<void> =>
  api.delete(`/files/${fileId}/resource/organisation`);

export const createNewOrganization = (fields: CreateNewOrganization): Promise<Organization> =>
  api.post('/organisations/new', fields);

export const fetchAddons = (): Promise<{ products: Addon[] }> => api.get('/payments/packages');

export const fetchOrganisationProducts = (organisationId: string): Promise<Products> =>
  api.get(`/payments/organisations/${organisationId}/products`);

export const addOrganisationAddon = (
  organisationId: string,
  body: {
    prices: string[];
  },
): Promise<void> => api.post(`/payments/organisations/${organisationId}/packages`, body);

export const removeOrganisationAddon = (organisationId: string, priceId: string): Promise<void> =>
  api.delete(`/payments/organisations/${organisationId}/packages/${priceId}`);

export const fetchOrganisationPrices = (organisationId: string): Promise<OrganisationPrices> =>
  api.get(`/payments/organisations/${organisationId}/prices`);

export const updateOrganisationMember = (
  organisationId: string,
  memberId: string,
  body: Partial<OrgMember>,
): Promise<OrgMember> => api.patch(`organisations/${organisationId}/members/${memberId}`, body);
