import i18n from 'i18next';
import Locale from 'data/enums/Locale';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

const SUPPORTED_LANGUAGES = Object.values(Locale);

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    ns: ['translation'],
    defaultNS: 'translation',
    returnNull: false,
    returnEmptyString: false,
    fallbackLng: Locale.ENGLISH,
    supportedLngs: SUPPORTED_LANGUAGES,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      customHeaders: {
        // Seems to cause issues in case the translation file is cached
        'Cache-Control': 'no-cache',
      },
    },
  })
  .then(() => {
    document.documentElement.setAttribute('lang', i18n.language);
  });

i18n.on('languageChanged', (lng) => {
  document.documentElement.setAttribute('lang', lng);
});

export default i18n;
