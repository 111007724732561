import { googleAnalytics } from 'app/constants/app';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import Cookies from 'js-cookie';
import { allCookies, cookiesConsentKey, essentialCookies } from './constants';

export const handleAcceptCookies = (acceptType: 'all' | 'essential' = 'essential') => {
  const url = new URL(window.location.href);
  const domain = url.hostname.split('.').slice(-2).join('.');
  const cookiesToAccept = acceptType === 'all' ? allCookies : essentialCookies;

  Cookies.set(cookiesConsentKey, JSON.stringify(cookiesToAccept), {
    path: '/',
    expires: 365,
    domain: domain === 'localhost' ? domain : `.${domain}`,
  });
};

export const initializeReactGA = () => {
  ReactGA.initialize(googleAnalytics);
  TagManager.initialize({ gtmId: import.meta.env.QIT_GOOGLE_TAG_MANAGER_ID });
  ReactGA.pageview(window.location.pathname + window.location.search);
};

export * from './constants';
