export enum FactTypeValue {
  CALCULATION = 'calculation',
  PATIENT = 'patient',
  QUESTION = 'question',
  TEXT = 'text',
  SESSION = 'session',
  NORM = 'norm',
  QUESTIONNAIRE_CONTAINER = 'questionnaireContainer',
  PROPERTY = 'property',
}
