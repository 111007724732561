export const basePath = '/';

const applyBasePathTo = (path) => `${basePath}${path}`;

// Shared paths

export const videoChatPath = (roomName) => applyBasePathTo(`video-chat/${roomName}`);

export const signInPath = applyBasePathTo('sign-in');

export const signOutPath = applyBasePathTo('sign-out');

export const resetPasswordPath = applyBasePathTo('reset-password');

export const expiredTokenPath = applyBasePathTo('expired-token');

export const confirmPasswordPath = (token) => applyBasePathTo(`confirm-password/${token}`);

export const confirmEmailPath = (token) => applyBasePathTo(`confirm-email/${token}`);

export const signUpPath = applyBasePathTo('sign-up');
export const signUpClientPath = `${signUpPath}/client`;
export const signUpTherapistPath = `${signUpPath}/therapist`;

export const activateMessagePath = applyBasePathTo('activation-message');

export const signUpInvitePath = (invite) => `${signUpPath}/${invite}`;
export const therapistSignUpPath = (invite) => `${signUpTherapistPath}/${invite}`;
export const clientSignUpPath = (invite) => `${signUpClientPath}/${invite}`;

export const paginatedPath = (path) => (page) => `${path}/page/${page}`;

export const acceptRecordAccessPath = (token) => applyBasePathTo(`record/access/${token}`);

export const publicPortfolioPath = (token: string) => applyBasePathTo(`portfolio/${token}`);

export const publicIntakePath = (organisationTitle: string, token: string) =>
  applyBasePathTo(`intakes/${organisationTitle}/${token}`);

export const publicIntakeConfirmationPath = () => applyBasePathTo(`intakes/confirmation`);

export const publicPortfolioDocumentPreviewPath = (token: string, documentId: string) =>
  `${publicPortfolioPath(token)}/document/${documentId}`;

export const LOCATION_STATES = {
  REDIRECT_PATH: 'redirectPath',
  BACKLINK: 'backlink',
} as const;
