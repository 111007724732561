import c from 'ui/styles/_colors.module.scss';
import w from 'ui/styles/_weights.module.scss';

const getBorderWidth = (error?: string, small?: boolean): string => {
  if (error && !small) {
    return '2px';
  }
  return '1px';
};

export const customStyles = (size?: string, error?: string) => ({
  control: (provided, state) => {
    const {
      selectProps: { menuIsOpen, isDisabled },
    } = state;
    const getBorderColor = () => {
      if (error) {
        return c.error;
      }

      return menuIsOpen ? c.secondary500 : c.base800;
    };

    return {
      backgroundColor: isDisabled ? c.base200 : c.base100,
      border: `${getBorderWidth(error, size === 'small')} solid ${getBorderColor()}`,
      borderBottomLeftRadius: menuIsOpen ? 0 : '4px',
      borderBottomRightRadius: menuIsOpen ? 0 : '4px',
      padding: size === 'small' ? '0 7px' : '4px 7px',
      height: size === 'small' ? 36 : 48,
      boxShadow: 'none',
      '&:hover': {},
      svg: { color: isDisabled ? `${c.base700} !important` : c.secondary500 },
    };
  },
  placeholder: (provided, { selectProps }) =>
    selectProps.isDisabled ? { visibility: 'hidden' } : { color: c.base700 },
  menu: (provided, state) => ({
    padding: 0,
    border: `${getBorderWidth(error, size === 'small')} solid ${c.secondary500}`,
    zIndex: 5,
    overflow: 'hidden',
  }),
  option: (provided, state) => {
    const {
      value,
      selectProps: { activeOptions, getOptionValue },
      isSelected,
      isFocused,
      isDisabled,
    } = state;
    const isChoosedOption =
      !!activeOptions?.some((option) => {
        if (getOptionValue) {
          return getOptionValue(option) === value;
        }
        return option.value === value;
      }) || isSelected;

    const isOptionColor = (): string => {
      if (state.isDisabled) {
        return c.base800;
      }
      return isChoosedOption || isFocused ? `${c.secondary700}` : `${c.base900}`;
    };

    const isBackgroundColor = (): string => {
      if (isDisabled) {
        return c.base200;
      }
      if (isFocused) {
        return c.secondary200;
      }
      return c.base100;
    };

    return {
      display: 'block',
      color: isOptionColor(),
      backgroundColor: isBackgroundColor(),
      fontWeight: isChoosedOption ? w.semiBold : w.regular,
      padding: size === 'small' ? '8px 16px !important' : '12px 16px !important',
      width: '100%',
      '& .icon': {
        marginRight: '4px',
        width: '12px',
        height: '12px',
      },
      '&:hover': {
        backgroundColor: c.secondary200,
        color: c.secondary700,
        cursor: 'pointer',
      },
    };
  },
  dropdownIndicator: (provided, state) => {
    const { isDisabled } = state;
    return {
      color: isDisabled ? c.base700 : c.secondary700,
      padding: size === 'small' ? '6px 8px' : 'auto',
      '&:hover': {
        color: c.secondary500,
      },
    };
  },
  container: (provided) => ({ ...provided, opacity: 1 }),
  singleValue: (provided, state) => ({ ...provided, color: state.isDisabled ? c.base800 : c.base900 }),
});
