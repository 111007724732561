import axios, { AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { getStoredRefreshToken, getStoredToken, setStoredRefreshToken, setStoredToken } from 'data';
import { ITokenData } from 'data/types';
import { isRefreshUrl, storeTargetPageAndLogout } from './utils';

// global flag to check if token refreshing
let isTokenRefreshing = false;
// array to store request configs which was called during refreshing and should be executed after refresh
const requestsToExecuteAfterRefresh: AxiosRequestConfig[] = [];

export const requestInterceptor = async (config: AxiosRequestConfig) => {
  const controller = new AbortController();
  const newConfig = { ...config };
  const { headers = {} } = newConfig;

  let accessToken = getStoredToken();

  if (!accessToken) return newConfig;

  // if request was called during refresh
  if (isTokenRefreshing && !isRefreshUrl(newConfig.url || '')) {
    // store it to execute later
    requestsToExecuteAfterRefresh.push(newConfig);
    // and cancel current attempt
    controller.abort();
    newConfig.signal = controller.signal;
    return newConfig;
  }

  const decodedToken: ITokenData = jwtDecode(accessToken);
  const { exp } = decodedToken;

  const tokenHasExpired = new Date() >= new Date(exp * 1000);
  // 5 seconds margin
  const tokenWillExpireSoon = new Date() >= new Date(exp * 1000 - 5_000);

  if ((tokenHasExpired || tokenWillExpireSoon) && !isRefreshUrl(newConfig.url || '')) {
    try {
      isTokenRefreshing = true;
      const refreshToken = getStoredRefreshToken();
      const refreshResponse = await axios.post(`${newConfig.baseURL}/tokens/refresh`, {
        refreshToken,
      });

      setStoredToken(refreshResponse.data.token);
      setStoredRefreshToken(refreshResponse.data.refreshToken.value);
      accessToken = refreshResponse.data.token;

      // after successful refresh check if there are some requests which was called during refresh
      if (requestsToExecuteAfterRefresh.length) {
        // execute them
        requestsToExecuteAfterRefresh.forEach(async (requestConfig) => {
          if (requestConfig.headers) {
            requestConfig.headers.authorization = `Bearer ${accessToken}`;
            await requestInterceptor(requestConfig);
          }
        });
        // and clear array after
        requestsToExecuteAfterRefresh.length = 0;
      }
    } catch (err) {
      storeTargetPageAndLogout();
      controller.abort();
      newConfig.signal = controller.signal;
      return newConfig;
    } finally {
      isTokenRefreshing = false;
    }
  }

  headers.authorization = `Bearer ${accessToken}`;
  newConfig.headers = headers;

  return newConfig;
};
