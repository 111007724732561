import { GraphType } from 'data/enums';
import { GraphItem } from 'data/types';

type DatasetTypes = {
  data: Array<number>;
  borderColor: string;
  backgroundColor: string;
  barPercentage?: number;
  minBarLength?: number;
};

type chartTypes = {
  labels: Array<number | string>;
  datasets: Array<DatasetTypes>;
};

export const transformData = (graph: GraphItem): chartTypes => {
  const isEvolution = graph.type === GraphType.EVOLUTION;
  const labels = graph.dots.map((dot, index) => (isEvolution ? index + 1 : dot.label));
  const data = graph.dots.map((dot) => dot.value);
  const color = graph.graph.scoreColor ? `${graph.graph.scoreColor}` : '#0A7EA5';
  return {
    labels,
    datasets: [{ data, borderColor: color, backgroundColor: color, barPercentage: 0.4, minBarLength: 3 }],
  };
};
