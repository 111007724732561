import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import theme from 'ui/utils/theme';
import { PUIText } from 'ui/components/text-pui';

import s from './styles.module.scss';

const Section = ({ label, children, className, ...props }) => (
  <div className={cx(s.section, className)} {...props}>
    <div className={s.sectionContainer}>
      <div className={s.sectionContainerLabel}>
        <PUIText
          color={theme.colors.base900}
          size={theme.typography.sizes.xs}
          weight={theme.typography.weights.regular}
        >
          {label}
        </PUIText>
      </div>
      <div className={s.sectionContainerContent}>{children}</div>
    </div>
  </div>
);

Section.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
Section.defaultProps = {
  label: null,
  className: null,
};

export { Section };
