import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory, withRouter } from 'react-router-dom';
import { signOutPath } from 'app/constants/url/shared';
import { getStoredUserId } from 'data/utils/userStorage';
import { toast } from 'ui';

const getCurrentTime = () => new Date().getTime();
export const idleLocalStorageKey = 'sessionExpiredTime';
// 3 hours
const timeout = 1000 * 60 * 180;

const IdleTimerComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const userId = getStoredUserId();

  const handleIdle = () => {
    localStorage.removeItem(idleLocalStorageKey);
    history.push(signOutPath);
    toast.error(t('inactivityLogoutToast'));
  };

  const handleAction = () => {
    localStorage.setItem(idleLocalStorageKey, `${getCurrentTime() + timeout}`);
  };

  const { start, reset, pause } = useIdleTimer({
    startManually: true,
    element: document,
    onIdle: handleIdle,
    onAction: handleAction,
    timeout,
    debounce: 250,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  });

  useEffect(() => {
    const isSessionExpired =
      localStorage.getItem(idleLocalStorageKey) && getCurrentTime() > Number(localStorage.getItem(idleLocalStorageKey));

    if (userId && isSessionExpired) {
      handleIdle();
    }
  }, []);

  // we should reset and pause timer if user signs out
  useEffect(() => {
    if (userId) {
      start();
    }

    if (!userId) {
      reset();
      pause();
    }
  }, [userId]);

  return null;
};

export default withRouter(IdleTimerComponent);
