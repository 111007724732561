import React from 'react';
import { Modal, ModalProps } from '@panenco/ui';
import cx from 'classnames';

import s from './styles.module.scss';

interface PopupProps extends ModalProps {
  isFullscreen?: boolean;
}

const PUIPopup = ({ className, isFullscreen, ...props }: PopupProps) => (
  <Modal autoFocus={false} className={cx(s.root, isFullscreen && s.rootFullscreen, className)} {...props} />
);
const { Header, Footer, Body, Title } = Modal;

const PopupNamespace = Object.assign(PUIPopup, {
  Header,
  Footer,
  Body,
  Title,
});

export { PopupNamespace as PopupPUI };
export * from './PopupButtons';
