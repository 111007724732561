import cx from 'classnames';
import { PUIButton, PUIButtonIcon, toast, Avatar } from 'ui';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

const ONE_MB_IN_B = 1048576;

export const FileInput = ({
  toastErrorKey,
  setFieldValue,
  name,
  avatar,
  avatarClassName,
  handleSubmit,
  fileText,
  disabled,
  isLoading,
  children,
  value,
  defaultLogo,
  className,
  icon,
  firstName,
  lastName,
  inputButtonShown,
  acceptOnlyImages,
  showDeleteButton,
  multiple,
}) => {
  const { t } = useTranslation();

  const [imgUrl, setImgUrl] = useState('');

  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length) {
      const newFormValue = avatar ? [...acceptedFiles] : [...(value || []), ...acceptedFiles];
      setFieldValue && setFieldValue(name, newFormValue);
      // eslint-disable-next-line
      avatar && setImgUrl(URL.createObjectURL(newFormValue[0]));
    }

    const rejectedFilesExist = rejectedFiles.length !== 0;

    if (rejectedFilesExist) {
      toast.error(toastErrorKey || t(rejectedFiles?.[0]?.errors?.[0]?.code) || t('fileUploadError'));
    }
    if (!avatar && !rejectedFilesExist && handleSubmit) handleSubmit(acceptedFiles);
  };

  const onDelete = () => {
    setFieldValue(name, null);
  };

  const renderDropZoneContent = (fileProps) => (
    <div className={cx((disabled || isLoading) && s.dropzoneDisabled)} {...fileProps.getRootProps()}>
      <input data-cy="fileUploadInput" {...fileProps.getInputProps()} />
      {children || (
        <PUIButton type="button" variant="contained" color="primary" disabled={disabled} isLoading={isLoading}>
          {fileText}
        </PUIButton>
      )}
    </div>
  );
  const renderImageDropZone = (fileProps) => (
    <div className={cx(s.imageDropZone, disabled && s.imageDropZoneDisabled)} {...fileProps.getRootProps()}>
      <input data-cy="avatarUploadInput" {...fileProps.getInputProps()} />
      <div className={s.imageDropZonePreview}>
        <div className={s.avatarContainer}>
          {value && value.length ? (
            <Avatar
              className={cx(s.avatarContainerAvatar, avatarClassName)}
              src={value && value[0] instanceof File ? imgUrl : value}
            />
          ) : (
            <Avatar
              className={cx(s.avatarContainerAvatar, avatarClassName)}
              src={defaultLogo}
              firstName={firstName}
              lastName={lastName}
            />
          )}
          {inputButtonShown && (
            <PUIButtonIcon icon="camera" iconSize="md">
              {t('choosePhoto')}
            </PUIButtonIcon>
          )}
        </div>
      </div>
    </div>
  );

  const renderIconDropZone = (fileProps) => (
    <div className={cx(s.iconDropZone, disabled && s.iconDropZoneDisabled)} {...fileProps.getRootProps()}>
      <input {...fileProps.getInputProps()} />
      {children || <PUIButtonIcon icon="upload">{fileText}</PUIButtonIcon>}
    </div>
  );

  const accept =
    avatar || acceptOnlyImages
      ? {
          'image/*': ['.png', '.webp', '.jpeg', '.jpg'],
        }
      : '*';

  return (
    <div className={cx(s.dropzoneContainer, className)}>
      <Dropzone accept={accept} onDrop={onDrop} maxSize={50 * ONE_MB_IN_B} multiple={multiple}>
        {(fileProps) => {
          if (avatar) {
            return renderImageDropZone(fileProps);
          }
          if (icon) {
            return renderIconDropZone(fileProps);
          }
          return renderDropZoneContent(fileProps);
        }}
      </Dropzone>
      {avatar && value && showDeleteButton && (
        <PUIButtonIcon onClick={onDelete} className={s.imageDropZoneDelete} icon="remove" />
      )}
    </div>
  );
};

FileInput.defaultProps = {
  children: null,
  style: null,
  classNameDropZone: null,
  inputStyle: null,
  className: null,
  firstName: null,
  lastName: null,
  multiple: false,
  fileText: 'Upload',
  accept: null,
  avatar: false,
  icon: false,
  value: null,
  toastErrorKey: null,
};

FileInput.propTypes = {
  classNameDropZone: PropTypes.string,
  children: PropTypes.node,
  /* eslint-disable-next-line */
  style: PropTypes.object,
  /* eslint-disable-next-line */
  inputStyle: PropTypes.object,
  className: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  multiple: PropTypes.bool,
  fileText: PropTypes.string,
  accept: PropTypes.string,
  setFieldValue: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  avatar: PropTypes.bool,
  icon: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  toastErrorKey: PropTypes.string,
  showDeleteButton: PropTypes.bool,
};
