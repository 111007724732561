import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { Bar } from 'react-chartjs-2';
import { transformData } from 'app/utils/helpers/transform-data-charts';
import { GraphItem } from 'data/types';
import { getOptionsHorizontalBar } from './options';
import { data as defaultData } from './default-data';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin);

type LineChartProps = {
  data?: GraphItem;
  className?: string;
  orientation?: string;
};
export const BarChart: React.FC<LineChartProps> = ({ data = defaultData, className, orientation }) => (
  <div style={{ height: `${data.dots.length * 20}px`, minHeight: '500px', minWidth: '600px' }} className={className}>
    <Bar options={getOptionsHorizontalBar(data, orientation)} data={transformData(data)} />
  </div>
);
