import { createContext } from 'react';

interface LayoutContext {
  setCustomClassName: (className: string) => void;
  setCustomContainerClassName: (className: string) => void;
  removeClassName: (className: string) => void;
  removeContainerClassName: (className: string) => void;
  useHideSidebar: () => void;
  toggleIsSidebarShown: () => void;
}

export const LayoutContext = createContext<LayoutContext>({
  setCustomClassName: (className: string) => {},
  setCustomContainerClassName: (className: string) => {},
  removeClassName: (className: string) => {},
  removeContainerClassName: (className: string) => {},
  useHideSidebar: () => {},
  toggleIsSidebarShown: () => {},
});
