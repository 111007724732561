import React from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { RoleEnum } from 'data/enums';
import { useHasAccess } from 'app/utils/hooks';
import { LOCATION_STATES } from 'app/constants/url/shared';

interface ProtectedRouteTypes extends RouteProps {
  allowedRoles: RoleEnum[];
}

const ProtectedRoute = ({ allowedRoles, ...props }: ProtectedRouteTypes) => {
  const location = useLocation();
  const hasAccess = useHasAccess(allowedRoles);

  if (hasAccess) {
    return <Route {...props} />;
  }
  return (
    <Redirect
      to={{
        pathname: '/',
        state: {
          [LOCATION_STATES.REDIRECT_PATH]: location,
        },
      }}
    />
  );
};

export default ProtectedRoute;
