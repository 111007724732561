import { RoleEnum } from 'data/enums';
import { useTokenData } from 'data/hooks/auth';

export function useHasRole(role: RoleEnum): boolean {
  const tokenData = useTokenData();

  if (!tokenData) {
    return false;
  }

  const hasRole = tokenData.roles.includes(role);

  return hasRole;
}
