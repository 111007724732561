import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import { gridLayout } from '@panenco/ui';

import { ViewportContext, ViewportContextInterface } from '.';

interface ViewportProviderProps {
  children: React.ReactNode;
}

export const ViewportProvider: React.FC<ViewportProviderProps> = ({ children }): JSX.Element => {
  const isMobile = useMediaQuery(`(max-width: ${parseInt(gridLayout.xs.break, 10) - 1}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${gridLayout.xs.break}) and (max-width: ${parseInt(gridLayout.sm.break, 10) - 1}px)`,
  );
  const isTabletLandscape = useMediaQuery(
    `(min-width: ${gridLayout.sm.break}) and (max-width: ${parseInt(gridLayout.md.break, 10) - 1}px)`,
  );
  const isDesktop = useMediaQuery(
    `(min-width: ${gridLayout.md.break}) and (max-width: ${parseInt(gridLayout.lg.bottom, 10) - 1}px)`,
  );
  const isDesktopLarge = useMediaQuery(`(min-width: ${gridLayout.lg.bottom})`);

  const context: ViewportContextInterface = {
    isMobile,
    isTablet,
    isTabletLandscape,
    isDesktop,
    isDesktopLarge,
  };

  return <ViewportContext.Provider value={context}>{children}</ViewportContext.Provider>;
};
