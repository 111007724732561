import React, { useMemo, useReducer } from 'react';
import { TitleContext, TitleType, Title } from '.';

type Props = {
  children: React.ReactNode;
};

enum ACTIONS {
  ADD_TAB = 'ADD_TAB',
  REMOVE_TAB = 'REMOVE_TAB',
}

type TitleState = {
  title: TitleType[];
};

type TitleAction = {
  type: keyof typeof ACTIONS;
  payload: TitleType;
};

const initialState: TitleState = {
  title: [],
};

const titleReducer = (state: TitleState, action: TitleAction) => {
  switch (action.type) {
    case ACTIONS.ADD_TAB:
      return { title: [...state.title, action.payload] };
    case ACTIONS.REMOVE_TAB:
      return { title: state.title.filter((item) => item.id !== action.payload.id) };
    default:
      return state;
  }
};

const TitleProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(titleReducer, initialState);

  const addTab = (tab: TitleType) => {
    dispatch({ type: ACTIONS.ADD_TAB, payload: tab });
  };

  const removeTab = (tab: TitleType) => {
    dispatch({ type: ACTIONS.REMOVE_TAB, payload: tab });
  };

  const value = useMemo(() => ({ title: state.title, addTab, removeTab }), [state]);

  return (
    <TitleContext.Provider value={value}>
      <Title />
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;
