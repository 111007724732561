export const TOKEN_LOCALSTORAGE_KEY = 'token';
export const REFRESH_TOKEN_LOCALSTORAGE_KEY = 'refresh-token';

export function getStoredToken(): string | null {
  return localStorage.getItem(TOKEN_LOCALSTORAGE_KEY);
}

export function setStoredToken(token: string): void {
  localStorage.setItem(TOKEN_LOCALSTORAGE_KEY, token);
}

export function clearStoredToken(): void {
  localStorage.removeItem(TOKEN_LOCALSTORAGE_KEY);
}

export function getStoredRefreshToken(): string | null {
  return localStorage.getItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
}

export function setStoredRefreshToken(refreshToken: string): void {
  localStorage.setItem(REFRESH_TOKEN_LOCALSTORAGE_KEY, refreshToken);
}

export function clearStoredRefreshToken(): void {
  localStorage.removeItem(REFRESH_TOKEN_LOCALSTORAGE_KEY);
}