import React, { ReactElement } from 'react';
import { Button, ButtonProps } from '@panenco/ui';
import cx from 'classnames';

import s from './styles.module.scss';

export type PUIButtonProps = ButtonProps & {
  borderWidth?: number;
  color?: 'primary' | 'secondary';
  component?: string;
  size?: 'regular' | 'small';
  [key: string]: any;
};

export const PUIButton: React.FC<PUIButtonProps> = ({
  className,
  color = 'primary',
  variant = 'outlined',
  size = 'regular',
  borderWidth = 2,
  ...props
}): ReactElement => (
  <Button
    className={cx(s.root, s[`${color}--${variant}`], size === 'small' && s.rootSmall, className)}
    style={{ borderWidth: `${borderWidth}px` }}
    variant={variant}
    {...props}
  />
);
