import { AnalyticsEvent } from 'data/enums';
import { useGTMEvents, useIntercomEvents } from '../services';

export const useTrackAnalyticsEvent = (): {
  trackEvent: (key: AnalyticsEvent, data?: { [key: string]: any }) => void;
} => {
  const { trackEvent: trackGTMEvent } = useGTMEvents();
  const { trackIntercomEvent } = useIntercomEvents();

  const trackEvent = (key: AnalyticsEvent, data?: { [key: string]: any }) => {
    trackGTMEvent(key, data);
    trackIntercomEvent(key, data);
  };

  return { trackEvent };
};
