import React from 'react';
import PropTypes from 'prop-types';
import { SwatchesPicker } from 'react-color';

import s from './styles.module.scss';

class ColorPicker extends React.Component {
  static propTypes = {
    value: PropTypes.string,
  };

  static defaultProps = {
    value: '#3C3C3C',
  };

  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState((prevState) => ({ displayColorPicker: !prevState.displayColorPicker }));
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = async (color) => {
    await this.props.setFieldValue(this.props.name, color.hex);
    await this.setState({ displayColorPicker: false });
  };

  render() {
    // const { className, leftLabel, rightLabel, minValue, maxValue, step } = this.props;

    return this.state.displayColorPicker ? (
      <div className={s.color}>
        <SwatchesPicker height={565} color={this.props.value} onChange={this.handleChange} />
      </div>
    ) : (
      <button
        style={{ backgroundColor: this.props.value }}
        className={s.button}
        onClick={this.handleClick}
        disabled={this.props.disabled}
      />
    );
  }
}

export { ColorPicker };
