import cx from 'classnames';
import { useField } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import s from './styles.module.scss';

const FormControl = ({
  labelText,
  labelSubText,
  footerText,
  inputComponent,
  inputComponentChildren = null,
  className,
  inputClassName,
  showError = true,
  shared,
  setFieldValue,
  ...props
}) => {
  const Input = inputComponent;
  const [field, meta] = useField(props);
  const { t } = useTranslation();
  const error = !props.isList && meta.touched && (meta.error || props.error);

  return (
    <div className={cx(s.formControl, className)}>
      {labelText && (
        <div className={s.formControlLabelWrapper}>
          {/* eslint-disable-next-line */}
          <label
            htmlFor={props.id}
            className={cx(
              s.formControlLabel,
              shared && s.formControlLabelShared,
              (props.disabled || props.isDisabled) && s.formControlLabelDisabled,
            )}
          >
            {labelText}
          </label>

          {labelSubText && <div className={s.formControlLabelSubText}>{labelSubText}</div>}
        </div>
      )}
      <Input
        {...props}
        setFieldValue={setFieldValue}
        error={error && t(`errors.${error}`)}
        className={cx(s.formControlInput, shared && s.formControlInputShared, inputClassName)}
        id={props.id || `input-field-id-${props.name}`}
      >
        {inputComponentChildren}
      </Input>
      {/* {error && (
        <div className={s.formControlError} data-cy="errorMessageCy">
          {t(`errors.${error}`)}
        </div>
      )} */}
      {footerText && <div className={s.formControlFooter}>{footerText}</div>}
    </div>
  );
};

FormControl.defaultProps = {
  className: null,
  children: null,
  labelText: null,
  labelSubText: null,
  footerText: null,
  error: null,
  shared: false,
};

FormControl.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  labelText: PropTypes.string,
  labelSubText: PropTypes.string,
  footerText: PropTypes.string,
  inputComponent: PropTypes.func.isRequired,
  error: PropTypes.string,
  shared: PropTypes.bool,
};

export default FormControl;
