import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import theme from 'ui/utils/theme';
import { PUIText } from '../text-pui';
import { Session } from './session';
import { QuestionChips } from './questionnaire-chips';
import { isPast, isToday, isFuture } from '../../utils/actions';

import s from './styles.module.scss';
import { SessionStatusEnum } from '../../../app/constants/app';
import { sortByNumericProperty } from 'app/utils/helpers/sortByNumericProperty';

const Trajectory = React.forwardRef(
  ({ isProtocol, className, sessions, to, notesScreen, trajectoryIsEnded, ...props }, ref) =>
    notesScreen ? (
      <div className={cx(s.trajectory, className)} {...props}>
        {sortByNumericProperty(sessions, 'orderIndex').map((session, index) => (
          <Session {...props} key={session.id} to={to} session={session} notesScreen={notesScreen} index={index + 1} />
        ))}
        {!sessions.length && trajectoryIsEnded ? (
          <PUIText size={theme.typography.sizes.xs}>{props.text.noEvents}</PUIText>
        ) : null}
      </div>
    ) : (
      <div className={cx(s.trajectory, className)} ref={ref} {...props}>
        {sessions.length
          ? sortByNumericProperty(sessions, 'orderIndex').map((session, index) => (
              <Session {...props} sessions={sessions} key={session.id} to={to} session={session} index={index + 1}>
                {isProtocol && !session.questionnaireContainers && session.questionnaires
                  ? session.questionnaires.map((el) => (
                      <QuestionChips key={el.getid} questionnaire={el}>
                        {el.abbreviation}
                      </QuestionChips>
                    ))
                  : null}
                {!isProtocol &&
                  session.questionnaireContainers &&
                  session.questionnaireContainers.map((el) => (
                    <QuestionChips
                      sessions={sessions}
                      session={session}
                      key={el.id}
                      questionnaire={el}
                      isFinished={trajectoryIsEnded || session.status === SessionStatusEnum.finished}
                      isSessionSent={session.status === SessionStatusEnum.sent}
                      sessionClockStatus={!isFuture(session.startDate)}
                      isPast={isPast(session.startDate) && !isToday(session.startDate)}
                    >
                      {el.questionnaire.abbreviation}
                    </QuestionChips>
                  ))}
              </Session>
            ))
          : null}
        {!sessions.length && trajectoryIsEnded ? (
          <PUIText size={theme.typography.sizes.xs}>{props.text.noEvents}</PUIText>
        ) : null}
        {props.addSession ? props.addSession : null}
      </div>
    ),
);

Trajectory.propTypes = {
  className: PropTypes.string,
  sessions: PropTypes.arrayOf(PropTypes.object),
  to: PropTypes.string.isRequired,
  notesScreen: PropTypes.bool,
  isProtocol: PropTypes.bool,
  trajectoryIsEnded: PropTypes.bool,
};

Trajectory.defaultProps = {
  className: null,
  notesScreen: false,
  isProtocol: false,
  trajectoryIsEnded: false,
};

export { Trajectory };
