import {
  isPast as isPastFns,
  isFuture as isFutureFns,
  isToday as isTodayFns,
  isSameDay as isSameDayFns,
  format as formatFns,
  parseISO,
} from 'date-fns';

export const format = (dateStr, formatStr) => {
  const dateObj = new Date(dateStr);
  return formatFns(dateObj, formatStr);
};

export const timeFormat = (date) => format(date, 'h:mm a');
export const dateFormat = (date) => format(date, 'dd MMM yyyy');
export const dateFormatDividers = (date) => format(date, 'dd/MM/yyyy');

export const isPast = (date) => isPastFns(parseISO(date));
export const isFuture = (date) => isFutureFns(parseISO(date));
export const isToday = (date) => isTodayFns(parseISO(date));
export const isSameDay = (day1, day2) => isSameDayFns(parseISO(day1), parseISO(day2));
