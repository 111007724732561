export enum IntakesTableAccessor {
  ID = 'id',
  NAME = 'name',
  REGISTERED = 'registered',
  STATUS = 'status',
  ASSIGNEE = 'assignee',
  FOLDERS = 'folders',
  ORDER_INDEX = 'orderIndex',
  CREATED_AT = 'createdAt',
  REGISTRATION_DATE = 'registrationDate',
}
