import { signOutPath } from 'app/constants/url/shared';
import { getStoredUserId } from 'data/utils/userStorage';

export const isRefreshUrl = (requestUrl: string) => new RegExp(/\/tokens\/refresh/).test(requestUrl);
export const isSignInUrl = (requestUrl: string) =>
  new RegExp(/(\/sign-in\/token|\/account\/confirm|\/sign-in)/).test(requestUrl);

export const storeTargetPageAndLogout = () => {
  const userId = getStoredUserId();
  const signOutUrl = new URL(signOutPath, window.location.origin);
  const signOutParam = new URLSearchParams(signOutUrl.search);

  signOutParam.set('targetPage', `${window.location.pathname}${window.location.search}`);
  signOutParam.set('userId', userId);

  signOutUrl.search = signOutParam.toString();

  window.location.replace(signOutUrl.href);
};
