import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { jwtDecode } from 'jwt-decode';
import { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { ITokenData } from 'data/types';
import {
  clearStoredRefreshToken,
  clearStoredToken,
  getStoredRefreshToken,
  getStoredToken,
} from 'data/utils/tokenStorage';
import { clearStoredUserId } from 'data/utils/userStorage';
import * as api from '../actions-query';

export const useTokenData = (): ITokenData | undefined => {
  const token = getStoredToken();

  try {
    return token ? jwtDecode(token) : undefined;
  } catch (e) {
    return undefined;
  }
};

export const usePatchConfirmEmailToken = (): UseMutationResult<{ token: string }, AxiosError, string, unknown> =>
  useMutation((token: string) => api.patchConfirmNewEmailToken(token));

export const useResetPassword = (): UseMutationResult<void, AxiosError, string, unknown> =>
  useMutation((email: string) => api.resetPassword(email));

export const useConfirmPassword = (): UseMutationResult<
  void,
  AxiosError,
  { token: string; values: { password: string; repeat: string } },
  unknown
> => useMutation(({ token, values }) => api.confirmPassword(token, values));

export const useLogout = () => {
  clearStoredToken();
  clearStoredUserId();
  Cookies.remove('user_id');
  Cookies.remove('auth', { path: '/' });
  api.removeAuthorizationHeaders();

  const refreshToken = getStoredRefreshToken();
  if (refreshToken) {
    try {
      api.logout(refreshToken);
    } catch (err) {
      return new Error(err);
    }
  }
  clearStoredRefreshToken();
};

export const useRelogin = (token: string) => {
  if (token) {
    api.setAuthorization(token);
  }

  return true;
};
