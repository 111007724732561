import { IntakePermissions } from 'data/enums';
import { useProfile } from 'data/hooks';

interface UseIntakeAccess {
  isFullAccess: boolean;
  isAllClients: boolean;
  isMyClients: boolean;
  isNoAccess: boolean;
}

export const useIntakeAccess = (): UseIntakeAccess => {
  const { profile } = useProfile();

  const intakePermissions = profile?.intakePermission;

  const isFullAccess = intakePermissions === IntakePermissions.FULL_ACCESS;
  const isAllClients = intakePermissions === IntakePermissions.ALL_CLIENTS;
  const isMyClients = intakePermissions === IntakePermissions.MY_CLIENTS;
  const isNoAccess = intakePermissions === IntakePermissions.NO_ACCESS;

  return { isFullAccess, isAllClients, isMyClients, isNoAccess };
};
