import { api } from 'data';
import { Note, ArrayResponseType, CreateNoteBody, NoteResponse, InteractionActivityEntity } from 'data/types';

export interface NoteBodyToEdit {
  title?: string;
  body?: string;
}

export const fetchRecordNotes = (recordId: string, params: string): Promise<ArrayResponseType<Note>> =>
  api.get(`/notes/records/${recordId}?${params}`);

export const fetchNotesWithAttachments = (
  recordId: string,
  params: string,
): Promise<ArrayResponseType<InteractionActivityEntity>> => api.get(`activities/records/${recordId}/notes?${params}`);

export const fetchTrajectoryNotes = (trajectoryId: string): Promise<Note[]> =>
  api.get(`/notes/trajectories/${trajectoryId}`);

export const fetchSessionNotes = (sessionId: string): Promise<Note[]> => api.get(`/notes/sessions/${sessionId}`);

export const fetchNote = (id: string): Promise<Note> => api.get(`/notes/${id}`);

export const deleteNote = (id: string): Promise<unknown> => api.delete(`/notes/${id}`);

export const editNote = (id: string, body: NoteBodyToEdit): Promise<unknown> => api.patch(`/notes/${id}`, body);

export const createPersonalNote = (recordId: string, body: CreateNoteBody): Promise<NoteResponse> =>
  api.post(`/notes/records/${recordId}`, body);

export const createInteractionNote = (interactionId: string, body: CreateNoteBody): Promise<NoteResponse> =>
  api.post(`/notes/interactions/${interactionId}`, body);

export const createSessionNote = (sessionId: string, body: CreateNoteBody): Promise<NoteResponse> =>
  api.post(`/notes/sessions/${sessionId}`, body);

export const createActivityNote = (activityId: string, body: CreateNoteBody): Promise<NoteResponse> =>
  api.post(`/notes/activities/${activityId}`, body);
