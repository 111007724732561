import { FC, useContext, useEffect } from 'react';
import { v4 as uuid } from 'uuid';
import { TitleContext } from './context';

type TitleAnchorProps = {
  tab: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setId?: (id: string) => {};
};

export const TitleAnchor: FC<TitleAnchorProps> = ({ tab, setId }) => {
  const { addTab, removeTab } = useContext(TitleContext);

  useEffect(() => {
    const id = uuid();
    if (setId) {
      setId(id);
    }
    addTab({ name: tab, id });
    return () => {
      removeTab({ name: tab, id });
    };
  }, [tab]);

  return null;
};
