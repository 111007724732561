import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { TitleContext } from './context';

export const Title = () => {
  const { t } = useTranslation();
  const { title } = useContext(TitleContext);

  return (
    <Helmet>
      <title>{title?.map((tab) => t(`tabTitle.${tab.name}`)).join(' | ')}</title>
    </Helmet>
  );
};
