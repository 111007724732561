import { api } from 'data';
import { ArrayResponseType, IntakeComment } from 'data/types';

export const fetchIntakeComments = (intakeId: string, params?: string): Promise<ArrayResponseType<IntakeComment>> =>
  api.get(`comments/intakes/${intakeId}?${params}`);

export const createIntakeComment = (intakeId: string, body: { text: string }): Promise<IntakeComment> =>
  api.post(`comments/intakes/${intakeId}`, body);

export const editIntakeComment = (commentId: string, body: { text: string }): Promise<IntakeComment> =>
  api.patch(`comments/${commentId}`, body);

export const deleteIntakeComment = (commentId: string): Promise<void> => api.delete(`comments/${commentId}`);
