import { api } from 'data';
import { CreateInteraction, Interaction } from 'data/types';

export const createInteraction = (trajectory: string, body: CreateInteraction): Promise<Interaction> =>
  api.post(`/interactions/trajectories/${trajectory}`, body);

export const fetchInteraction = (interactionId: string): Promise<Interaction> =>
  api.get(`/interactions/${interactionId}`);

export const removeInteraction = (interactionId: string): Promise<void> => api.delete(`/interactions/${interactionId}`);
