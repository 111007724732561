import Cookies from 'js-cookie';

const USER_ID_LOCALSTORAGE_KEY = 'user_id';

export function getStoredUserId(): string {
  return localStorage.getItem(USER_ID_LOCALSTORAGE_KEY) || '';
}

export function setStoredUserId(userId: string): void {
  localStorage.setItem(USER_ID_LOCALSTORAGE_KEY, userId);
  // we should store user id in cookies for analytics configuration
  Cookies.set(USER_ID_LOCALSTORAGE_KEY, userId);
}

export function clearStoredUserId(): void {
  localStorage.removeItem(USER_ID_LOCALSTORAGE_KEY);
  Cookies.remove(USER_ID_LOCALSTORAGE_KEY);
}
