import { organizationKeys, organizationMembersKeys } from 'data/utils/hookKeys';
import queryString from 'qs';
import {
  SortDirection,
  OrganizationSortKeys,
  OrganizationStatus,
  OrganizationMembersSortKeys,
  IntakePermissions,
} from 'data/enums';
import {
  ArrayResponseType,
  BaseFetchListParams,
  Organization,
  EditOrganizationFields,
  OrgMember,
  BillingPortalFields,
  CreateOrganizationFields,
  InviteMemberFields,
  IOrganizationAdminDetails,
  IOrganizationStripeSubscriptions,
  IOrganizationStripeLink,
  DocumentFile,
  CreateNewOrganization,
  Addon,
  Products,
  OrganisationPrices,
  OrgTeamMember,
} from 'data/types';
import { AxiosError } from 'axios';
import { useQuery, UseMutationResult, useQueryClient, useMutation } from '@tanstack/react-query';
import * as api from '../actions-query';

export interface IOrganizationsSearchParams extends BaseFetchListParams {
  sort?: {
    [key in OrganizationSortKeys]?: SortDirection;
  };
  filters: {
    search?: string | null;
    status?: OrganizationStatus;
  };
}

export const defaultOrgSearchParams: IOrganizationsSearchParams = {
  sort: {
    [OrganizationSortKeys.ORGANIZATION_NAME]: SortDirection.asc,
  },
  limit: 12,
  offset: 0,
  filters: {
    status: OrganizationStatus.ACTIVE,
  },
};

export interface OrganizationMembersSearchParams extends BaseFetchListParams {
  filters?: {
    excludedFromBilling?: boolean | string;
    isActive?: number;
    enabled?: number;
    intakePermission?: IntakePermissions;
  };
  sort?: {
    [key in OrganizationMembersSortKeys]?: SortDirection;
  };
}

export const defaultOrgMembersSearchParams: OrganizationMembersSearchParams = {
  limit: 12,
  offset: 0,
};

export const useOrganizations = (searchParams: IOrganizationsSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<Organization>, AxiosError>(
    organizationKeys.all(query),
    () => api.fetchOrganizations(query),
    {
      staleTime: 0,
      cacheTime: 300000, // 5 minutes
      // refetchOnMount: true,
      // refetchOnReconnect: true,
      // refetchOnWindowFocus: true,
      refetchInterval: 60000, // 60 seconds
    },
  );
};

export const useOrganizationMembers = (organizationId?: string, searchParams = defaultOrgMembersSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<OrgMember>, AxiosError>(
    organizationMembersKeys.query(organizationId, query),
    () => api.fetchOrganizationMembers(organizationId, query),
    { enabled: !!organizationId },
  );
};

export const useOrganizationTeams = (organizationId?: string, searchParams = defaultOrgMembersSearchParams) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<OrgTeamMember>, AxiosError>(
    organizationMembersKeys.teams(organizationId, query),
    () => api.fetchOrganizationTeams(organizationId, query),
    { enabled: !!organizationId },
  );
};

export const useOrganizationMembersSimpleList = (
  organizationId?: string,
  searchParams = defaultOrgMembersSearchParams,
) => {
  const query = queryString.stringify(searchParams);
  return useQuery<ArrayResponseType<OrgMember>, AxiosError>(
    organizationMembersKeys.query(organizationId, query),
    () => api.fetchOrganizationMembersSimpleList(organizationId, query),
    { enabled: !!organizationId },
  );
};

export const useOrganization = (organizationId: string | undefined | null) =>
  useQuery<Organization, AxiosError>(
    organizationKeys.detail(organizationId),
    () => api.fetchOrganization(organizationId),
    {
      enabled: !!organizationId,
    },
  );

export const useOrganizationBillingPortal = (organizationId: string) =>
  useQuery<BillingPortalFields, AxiosError>(
    organizationKeys.billingPortal(organizationId),
    () => api.fetchBillingPortal(organizationId),
    {
      enabled: !!organizationId,
    },
  );

export const useCreateOrganization = (): UseMutationResult<
  unknown,
  any,
  { fields: CreateOrganizationFields },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ fields }) => api.createOrganization(fields), {
    onSuccess: () => {
      const query = queryString.stringify(defaultOrgSearchParams);
      queryClient.invalidateQueries(organizationKeys.all(query));
    },
  });
};

export const useEditOrganization = (): // searchParams?: IOrganizationsSearchParams,
UseMutationResult<unknown, any, { organizationId: string; fields: EditOrganizationFields }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ organizationId, fields }) => api.editOrganization(organizationId, fields), {
    onSuccess: (data, variables) => {
      // const query = queryString.stringify(searchParams, );
      queryClient.invalidateQueries(organizationKeys.all(''));
      queryClient.invalidateQueries(organizationKeys.detail(variables.organizationId));
      queryClient.invalidateQueries(organizationKeys.logo(data?.logo?.id));
    },
  });
};

export const useEditOrganizationOptimistically = (): // searchParams?: IOrganizationsSearchParams,
UseMutationResult<unknown, any, { organizationId: string; fields: EditOrganizationFields }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ organizationId, fields }) => api.editOrganization(organizationId, fields), {
    onMutate: async ({ organizationId, fields }) => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: organizationKeys.detail(organizationId) });

      // Snapshot the previous value
      const previousOrganizationState = queryClient.getQueryData(organizationKeys.detail(organizationId));

      // Optimistically update to the new value
      if (previousOrganizationState)
        queryClient.setQueryData(organizationKeys.detail(organizationId), { ...previousOrganizationState, ...fields });

      // Return a context object with the snapshotted value
      return { previousOrganizationState };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_, data, context) => {
      queryClient.setQueryData(organizationKeys.detail(data.organizationId), context?.previousOrganizationState);
    },
    // Always refetch after error or success:
    onSettled: (organization) => {
      queryClient.invalidateQueries(organizationKeys.all(''));
      queryClient.invalidateQueries(organizationKeys.detail(organization?.id));
      queryClient.invalidateQueries(organizationKeys.logo(organization?.id, organization?.logo?.id));
    },
  });
};

export const useDeleteOrganization = (): UseMutationResult<unknown, any, string, unknown> => {
  const queryClient = useQueryClient();
  return useMutation((organizationId) => api.deleteOrganization(organizationId), {
    onSuccess: () => {
      const query = queryString.stringify(defaultOrgSearchParams);
      queryClient.invalidateQueries(organizationKeys.all(query));
    },
  });
};

export const useAddMember = (): UseMutationResult<
  unknown,
  AxiosError,
  { organizationId: string; fields: InviteMemberFields },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ organizationId, fields }) => api.addMember(organizationId, fields), {
    onSuccess: () => {
      queryClient.invalidateQueries(organizationMembersKeys.all());
    },
  });
};

export const useOrganizationAdmin = (organizationId: string | null) =>
  useQuery<IOrganizationAdminDetails, AxiosError>(
    organizationKeys.organizationAdmin(organizationId),
    () => api.fetchOrganizationAdminDetails(organizationId),
    {
      enabled: !!organizationId,
    },
  );

export const useOrganizationStripeLink = (organizationId: string | null) =>
  useQuery<IOrganizationStripeLink, AxiosError>(
    organizationKeys.stripeLink(organizationId),
    () => api.fetchOrganizationStripeLink(organizationId),
    {
      enabled: !!organizationId,
    },
  );

export const useOrganizationsStripeSubscriptions = () =>
  useQuery<IOrganizationStripeSubscriptions, AxiosError>(organizationKeys.stripeSubscriptions(), () =>
    api.fetchOrganizationsStripeSubscriptions(),
  );

export const useUpgradeOrganisationSubscription = (): UseMutationResult<
  void,
  AxiosError,
  { organisationId: string; priceId: string },
  unknown
> => useMutation(({ organisationId, priceId }) => api.updateOrganisationSubscription(organisationId, priceId));

export const useOrganisationAddonPrice = ({ organisationId, priceId }: { organisationId: string; priceId: string }) =>
  useQuery<any, AxiosError>(organizationKeys.organisationAddonPrice(organisationId, priceId), () =>
    api.fetchOrganisationAddonPrice(organisationId, priceId),
  );

export const useOrganizationSubscriptionPortal = (): UseMutationResult<
  unknown,
  AxiosError,
  { organizationId: string; prices: string[] },
  unknown
> => useMutation(({ organizationId, prices }) => api.fetchOrganizationSubscriptionPortalLink(organizationId, prices));

export const useOrganizationLogo = (orgId: string, fileId: string) =>
  useQuery<DocumentFile, AxiosError>(
    organizationKeys.logo(orgId, fileId),
    () => api.fetchOrganizationLogo(fileId, orgId),
    {
      enabled: !!fileId,
    },
  );

export const useDeleteOrganizationLogo = (): UseMutationResult<
  unknown,
  void,
  { orgId: string; fileId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ orgId, fileId }) => api.deleteOrganizationLogo(fileId), {
    onSuccess: (_, { orgId, fileId }) => {
      queryClient.invalidateQueries(organizationKeys.detail(orgId));
    },
  });
};

export const useCreateNewOrganization = (): UseMutationResult<
  Organization,
  AxiosError,
  CreateNewOrganization,
  unknown
> => {
  const query = queryString.stringify(defaultOrgSearchParams);
  const queryClient = useQueryClient();
  return useMutation((fields) => api.createNewOrganization(fields), {
    onSuccess: () => queryClient.invalidateQueries(organizationKeys.all(query)),
  });
};

export const useAddons = () =>
  useQuery<{ products: Addon[] }, AxiosError>(organizationKeys.addons(), () => api.fetchAddons());

export const useOrganisationProducts = (organisationId: string) =>
  useQuery<Products, AxiosError>(
    organizationKeys.organisationAddons(organisationId),
    () => api.fetchOrganisationProducts(organisationId),
    {
      enabled: !!organisationId,
    },
  );

export const useAddOrganisationAddon = (): UseMutationResult<
  void,
  AxiosError,
  { organisationId: string; prices: string[] },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ organisationId, prices }) => api.addOrganisationAddon(organisationId, { prices }), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(organizationKeys.organisationAddons(variables.organisationId));
    },
  });
};

export const useRemoveOrganisationAddon = (): UseMutationResult<
  void,
  AxiosError,
  { organisationId: string; priceId: string },
  unknown
> => {
  const queryClient = useQueryClient();
  return useMutation(({ organisationId, priceId }) => api.removeOrganisationAddon(organisationId, priceId), {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(organizationKeys.organisationAddons(variables.organisationId));
    },
  });
};

export const useOrganisationPrices = (organisationId: string) =>
  useQuery<OrganisationPrices, AxiosError>(
    organizationKeys.organisationPrices(organisationId),
    () => api.fetchOrganisationPrices(organisationId),
    {
      enabled: !!organisationId,
    },
  );

export const useUpdateOrganisationMember = (
  organisationId: string,
  searchParams = defaultOrgMembersSearchParams,
): UseMutationResult<OrgMember, AxiosError, { memberId: string; body: Partial<OrgTeamMember> }, unknown> => {
  const queryClient = useQueryClient();
  return useMutation(({ memberId, body }) => api.updateOrganisationMember(organisationId, memberId, body), {
    onSuccess: () => {
      const query = queryString.stringify(searchParams);
      queryClient.invalidateQueries(organizationMembersKeys.teams(organisationId, query));
    },
  });
};
