import { IntakesTableAccessor, SortDirection, IntakeStatus } from 'data/enums';
import { intakesPath } from 'app/constants/url/therapist';
import { t } from 'i18next';
import { defaultRangeEndDate, defaultRangeStartDate } from 'data/hooks';

export const defaultIntakesSort = {
  sort: IntakesTableAccessor.REGISTRATION_DATE,
  direction: SortDirection.desc,
};

export enum IntakeOverviewTabs {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export const tabs = [
  {
    label: t('activeIntakes'),
    link: intakesPath,
    filter: `?status=${[IntakeStatus.REGISTERED, IntakeStatus.WAITING_LIST].join(',')}`,
    id: `intake-active-tab-id`,
    'data-intercom-target': `tab-intake-active`,
    'data-cy': `active-tab`,
    value: IntakeOverviewTabs.ACTIVE,
  },
  {
    label: t('inactiveIntakes'),
    link: intakesPath,
    filter: `?status=${[IntakeStatus.REFERRED, IntakeStatus.STARTED].join(',')}`,
    id: `intake-inactive-tab-id`,
    'data-intercom-target': `tab-intake-inactive`,
    'data-cy': `inactive-tab`,
    value: IntakeOverviewTabs.INACTIVE,
  },
];

export const activeStatusToFilter = [
  { value: [IntakeStatus.REGISTERED, IntakeStatus.WAITING_LIST].join(','), label: 'allStatuses' },
  { value: IntakeStatus.REGISTERED, label: IntakeStatus.REGISTERED },
  { value: IntakeStatus.WAITING_LIST, label: IntakeStatus.WAITING_LIST },
];

export const inactiveStatusToFilter = [
  { value: [IntakeStatus.REFERRED, IntakeStatus.STARTED].join(','), label: 'allStatuses' },
  { value: IntakeStatus.REFERRED, label: IntakeStatus.REFERRED },
  { value: IntakeStatus.STARTED, label: IntakeStatus.STARTED },
];

export const defaultIntakesFilters = {
  createdAtFrom: defaultRangeStartDate.toISOString(),
  createdAtTo: defaultRangeEndDate.toISOString(),
  status: [IntakeStatus.REGISTERED, IntakeStatus.WAITING_LIST].join(','),
};

export const intakeAllFoldersParamValue = 'all';
