import React from 'react';
import PropTypes from 'prop-types';
import theme from 'ui/utils/theme';
import { PUIText } from 'ui/components/text-pui';
import { Icon } from 'ui/components/icon';
import { t } from 'i18next';
import cx from 'classnames';
import s from './styles.module.scss';

class Dropdown extends React.Component {
  state = {
    open: false,
  };

  openClose = () => {
    this.setState((prevState) => ({ open: !prevState.open }));
  };

  render() {
    const { options, action, className, addEntity, disabled, actionHandler } = this.props;
    const { open } = this.state;

    return (
      <div
        className={cx(s.dropdown, disabled && s.dropdownDisabled, className)}
        // onClick={e => {
        //   e.preventDefault();
        //
        //   return addEntity ? addEntity() : this.openClose();
        // }}
        // type="button"
      >
        <Icon icon="plusCircle" className={s.dropdownIcon} />

        <PUIText size={theme.typography.sizes.xs} color={theme.colors.base900}>
          {action}
        </PUIText>

        <Icon icon="chevronRight" className={s.dropdownArrow} />

        <div className={s.dropdownMenu}>
          {options.map((option) => (
            <button
              className={s.dropdownMenuButton}
              onClick={() => {
                if (open) {
                  this.openClose();
                }
                return actionHandler(option.value);
              }}
              type="button"
              disabled={option.disabled}
            >
              <PUIText className={s.dropdownMenuOption} size={theme.typography.sizes.s} color={theme.colors.base900}>
                {t(option.label)}
              </PUIText>
            </button>
          ))}
        </div>
      </div>
    );
  }
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  action: PropTypes.string.isRequired,
  className: PropTypes.string,
  // addEntity: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  className: null,
};

export { Dropdown };
