import { api } from 'data';
import { ArrayResponseType, CreateTagFields, Tag } from 'data/types';

export const fetchTags = (params: string): Promise<ArrayResponseType<Tag>> => api.get(`/tags?${params}`);

export const createTag = (body: CreateTagFields): Promise<CreateTagFields> => api.post(`/tags`, body);

export const editTag = (tagId: string, body: CreateTagFields): Promise<CreateTagFields> =>
  api.patch(`/tags/${tagId}/edit`, body);

export const deleteTag = (tagId: string) => api.delete(`/tags/${tagId}`);
