export enum PaymentStatus {
  NOT_PAID = 'not_paid',
  PAID = 'paid',
}

export enum PaymentMethod {
  CASH = 'cash',
  MOBILE = 'mobile',
  TRANSFER = 'transfer',
  CARD = 'card',
}
