import { api } from 'data';
import { ArrayResponseType, EditUser, Profile, User, UserConnection, UserPicture, UserRole } from 'data/types';

export const resendInvite = (userId: string): Promise<unknown> => api.post(`/users/${userId}/invite-again`);

export const setProfilePicture = (userId: string, body: FormData): Promise<UserPicture> =>
  api.post(`/users/${userId}/profile-picture`, body);

export const deleteProfilePicture = (userId: string): Promise<unknown> =>
  api.delete(`/users/${userId}/profile-picture`);

export const getProfile = (): Promise<Profile> => api.get(`/users/profile`);

export const getUsersLearnworldLink = (): Promise<string> => api.get('/users/learnworld');

export const getUserConnections = (userId: string, filters: string): Promise<ArrayResponseType<UserConnection>> =>
  api.get(`/users/${userId}/connections?${filters}`);

export const getUserRoles = (userId: string, filters: string): Promise<ArrayResponseType<UserRole>> =>
  api.get(`/users/${userId}/roles?${filters}`);

export const getResetPasswordTokenStatus = (token: string): Promise<{ expiresAt: string }> =>
  api.get(`/users/password-reset/${token}`);

export const editUser = (userId: string, fields: EditUser): Promise<User> => api.patch(`/users/${userId}`, fields);
