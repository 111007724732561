import React from 'react';
import { getNameInitials } from 'app/utils';
import cx from 'classnames';
import { Avatar as MUIAvatar } from '@mui/material';
import { TextSize, themeMUI } from 'ui/utils/themeMUI';
import { PUIText } from '../text-pui';

import s from './styles.module.scss';

export interface AvatarProps {
  src?: string;
  firstName?: string;
  lastName?: string;
  size?: number;
  className?: string;
  textSize?: TextSize;
}

export const Avatar = ({
  firstName = 'Firstname',
  lastName = 'Lastname',
  className,
  size = 44,
  textSize = themeMUI.typography.l,
  ...props
}: AvatarProps) => (
  <MUIAvatar
    sx={{ bgcolor: themeMUI.palette.grey[200], width: size, height: size }}
    className={cx(s.root, className)}
    {...props}
  >
    <PUIText className={s.rootInitials} size={{ ...textSize, textSize: textSize.fontSize }}>
      {getNameInitials(firstName, lastName)}
    </PUIText>
  </MUIAvatar>
);

export const AvatarSmall = (props: AvatarProps) => <Avatar size={24} {...props} textSize={themeMUI.typography.xs} />;
