import type { ChartOptions } from 'chart.js';
import { GraphItem } from 'data/types';
import { getLabel } from '../utils/getLabel';
import { sortByNumericProperty } from 'app/utils/helpers/sortByNumericProperty';

export const getOptionsHorizontalBar = (graph: GraphItem, orientation?: string): ChartOptions<'bar'> => {
  const maxX = +graph.graph.maxScale;
  // !(+graph.graph.maxScale % +graph.graph.intervalScale)
  // ? +graph.graph.maxScale
  // : +graph.graph.maxScale + 1;
  const norms: any = [];
  const sortedBackgroundNorms = sortByNumericProperty(graph.graph.backgroundNorms, 'upperLimit');
  const normLimits: Array<number> = sortedBackgroundNorms.map((norm) => norm.upperLimit);
  const annotationLimits: Array<number> = [+graph.graph.minScale, ...normLimits, maxX];
  const correctOrientation = orientation || graph.graph.orientation;
  sortedBackgroundNorms.forEach((norm, index) => {
    const maxValue = index === graph.graph.backgroundNorms.length - 1 ? maxX : norm.upperLimit;
    norms.push({
      type: 'box',
      backgroundColor: `${norm.graphBackgroundColor}30`,
      borderWidth: 0,
      xMax: correctOrientation === 'vertical' ? null : maxValue,
      yMax: correctOrientation === 'vertical' ? maxValue : null,
      xMin: correctOrientation === 'vertical' ? null : annotationLimits[index],
      yMin: correctOrientation === 'vertical' ? annotationLimits[index] : null,
      drawTime: 'beforeDatasetsDraw',
      label: {
        type: 'label',
        drawTime: 'afterDraw',
        enabled: true,
        content: getLabel(norm.name),
        position: {
          x: 'center',
          y: 'end',
        },
        padding: {
          top: 6,
          left: 8,
          right: 8,
          bottom: 6,
        },
      },
      enter({ element }) {
        element.label.options.backgroundColor = `${norm.graphBackgroundColor}`;
        element.label.options.borderRadius = 6;
        element.label.options.display = true;
        return true;
      },
      leave({ element }) {
        element.label.options.display = false;
        return true;
      },
    });
  });
  return {
    indexAxis: correctOrientation === 'vertical' ? 'x' : 'y',
    maintainAspectRatio: false,
    elements: {
      bar: {
        borderRadius: 4,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
        text: graph.graph.titleAbove,
        font: {
          size: 16,
        },
      },
      tooltip: {
        enabled: false,
      },
      annotation: {
        annotations: { ...norms },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
          text: graph.graph.xLabel,
        },
        max: maxX,
        ticks: {
          stepSize: +graph.graph.intervalScale,
        },
        grid: {
          color: '#f8fafb',
        },
      },
      y: {
        ticks: {
          padding: 0,
        },
        grid: {
          color: '#f8fafb',
        },
      },
    },
  };
};
