import React from 'react';
import cx from 'classnames';
import { PUIButton, PUIButtonProps } from '../button-pui';

import s from './styles.module.scss';

interface Props {
  className?: string;
  cancelBtnProps?: PUIButtonProps;
  submitBtnProps?: PUIButtonProps;
}

const PopupButtons: React.FC<Props> = ({ className = '', submitBtnProps = {}, cancelBtnProps = {} }): JSX.Element => (
  <div className={cx(s.btnWrapper, className)}>
    <PUIButton
      color="primary"
      borderWidth={2}
      variant="outlined"
      {...cancelBtnProps}
      className={cx(s.btnOutlined, s.btn, cancelBtnProps.className)}
    >
      {cancelBtnProps?.children || 'Cancel'}
    </PUIButton>
    <PUIButton
      color="primary"
      borderWidth={2}
      variant="contained"
      {...submitBtnProps}
      className={cx(s.btnContained, s.btn, submitBtnProps.className)}
    >
      {submitBtnProps.children || 'Create'}
    </PUIButton>
  </div>
);

PopupButtons.defaultProps = {
  className: '',
  cancelBtnProps: {},
  submitBtnProps: {},
};

export { PopupButtons };
