export enum CookieTypes {
  essential = 'ess',
  advertisement = 'adv',
  functional = 'func',
  analytics = 'anl',
}

export const allCookies = {
  [CookieTypes.essential]: 1,
  [CookieTypes.advertisement]: 1,
  [CookieTypes.functional]: 1,
  [CookieTypes.analytics]: 1,
};

export const essentialCookies = {
  [CookieTypes.essential]: 1,
  [CookieTypes.advertisement]: 0,
  [CookieTypes.functional]: 0,
  [CookieTypes.analytics]: 0,
};

export const cookiesConsentKey = 'consent-policy';
