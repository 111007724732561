import { api } from 'data';
import { RecordInviteStatus } from 'data/enums';
import { ArrayResponseType, RecordInvite } from 'data/types';

export const fetchRecordInvites = (userId: string, params: string): Promise<ArrayResponseType<RecordInvite>> =>
  api.get(`/invites/users/${userId}?${params}`);

export const fetchInvite = (inviteId: string): Promise<RecordInvite> => api.get(`/invites/${inviteId}`);

export const patchInvite = (inviteId: string, userId: string, status: RecordInviteStatus): Promise<RecordInvite> =>
  api.patch(`/invites/${inviteId}/users/${userId}`, { status });
