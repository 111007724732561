import axios, { AxiosResponse } from 'axios';
import { setStoredRefreshToken, setStoredToken } from 'data';
import { setStoredUserId } from 'data/utils/userStorage';
import { isSignInUrl } from './utils';

export const onResponseSuccess = (res: AxiosResponse): AxiosResponse => {
  const { data, request } = res;
  // TODO implement in case auth via react query is needed
  // check for the status 204 in case if user have 2fa enabled
  if (isSignInUrl(request.responseURL) && request.status !== 204 && request.status !== 201) {
    setStoredToken(data.token);
    setStoredUserId(data.user.id);
    setStoredRefreshToken(data.refreshToken.value);
    axios.defaults.headers.common.authorization = `Bearer ${data.token}`;
  }
  return data;
};
