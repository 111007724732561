import React, { ReactElement, useContext } from 'react';
import { ButtonIcon, ButtonIconProps, IconVariantSize } from '@panenco/ui';
import cx from 'classnames';
import { TooltipContext } from 'app/utils/context';
import ReactTooltip from 'react-tooltip';
import { v4 as uuid } from 'uuid';
import s from './styles.module.scss';

export type PUIButtonIconProps = ButtonIconProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement> & {
    iconSize?: IconVariantSize;
    tooltipText?: string;
    variant?: 'outlined' | 'contained' | 'text';
  };

export const PUIButtonIcon = ({
  className,
  children,
  iconClassName,
  iconSize,
  tooltipText,
  id,
  ...props
}: PUIButtonIconProps): ReactElement => {
  const tooltipProps = useContext(TooltipContext);
  const tooltipId = id ? `${id}-tooltip` : uuid();

  const Button = (
    <ButtonIcon
      type="button"
      className={cx(s.root, className, !children && s.rootIconButton)}
      {...props}
      iconClassName={cx(s.iconStyle, iconSize && s[iconSize], iconClassName)}
      iconLeft
      id={id}
      data-tip
      data-for={tooltipId}
    >
      {children}
    </ButtonIcon>
  );

  if (!tooltipText) return Button;

  return (
    <>
      <ReactTooltip id={tooltipId} {...tooltipProps} arrowColor="transparent">
        {tooltipText}
      </ReactTooltip>
      {Button}
    </>
  );
};
