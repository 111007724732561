import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import theme from 'ui/utils/theme';
import { PUIText } from 'ui/components/text-pui';
import { Icon } from 'ui/components/icon';
import cx from 'classnames';
import { isPast, isToday, isSameDay, dateFormat } from '../../../utils/actions';
import s from './styles.module.scss';
import { SessionStatusEnum, questionnaireStatus } from '../../../../app/constants/app';
import { sortByNumericProperty } from 'app/utils/helpers/sortByNumericProperty';

const Session = ({ to, sessions, session, children, status, notesScreen, text, sessionName }) => {
  let allCompleted = null;
  if (session.questionnaireContainers)
    session.questionnaireContainers.map((questionnaire) => {
      if (questionnaire.get('status') !== questionnaireStatus.STATUS_COMPLETE)
        allCompleted = s.sessionContentNotFinished;
    });
  else if (session.questionnaires) {
    session.questionnaires.map((questionnaire) => {
      if (questionnaire.status !== questionnaireStatus.STATUS_COMPLETE) allCompleted = s.sessionContentNotFinished;
    });
  }

  const lastSession = sessions ? sessions.length && sortByNumericProperty(sessions, 'orderIndex')[-1] : null;

  const isUnanswered = lastSession && session ? !isSameDay(session.startDate, lastSession.startDate) : null;

  const isQuestionnaireUnanswered = session.questionnaireContainers
    ? session.questionnaireContainers.find((el) => el.status === questionnaireStatus.STATUS_SENT)
    : null;

  const isSent = session.status === SessionStatusEnum.sent;

  const redSession =
    isQuestionnaireUnanswered && isSent && isPast(session.startDate) && !isToday(session.startDate) && isUnanswered;

  const interaction = session.type && session.type === 'interaction';

  let interactionContent;

  if (interaction) {
    if (isPast(session.startDate) || isToday(session.startDate)) {
      interactionContent = 'Past';
    } else interactionContent = 'Future';
  }

  return to ? (
    <NavLink to={to(session.id)} className={s.session} activeClassName={s.activeSession}>
      <div
        className={cx(
          s.sessionContent,
          s[`sessionContent--${session.status}`],
          redSession && s.sessionContentUnanswered,
          allCompleted,
          s[`interactionContent${interactionContent}`],
        )}
      >
        <PUIText className={s.sessionContentName} color={theme.colors.base900} size={theme.typography.sizes.s}>
          {text[session.name] || sessionName} {session.orderIndex}
        </PUIText>

        <PUIText className={s.sessionContentDate} color={theme.colors.base900} size={theme.typography.sizes.xxs}>
          {session.startDate ? dateFormat(session.startDate) : dateFormat(session.createdAt)}
        </PUIText>

        {notesScreen ? (
          <PUIText className={s.sessionContentNotes} color={theme.colors.base900} size={theme.typography.sizes.s}>
            <Icon icon="fileText" /> {session.notes.length} {text.notes}
          </PUIText>
        ) : (
          children
        )}
      </div>
    </NavLink>
  ) : (
    <div className={s.session}>
      <div className={cx(s.sessionContent, status && s.sessionContentNotFinished)}>
        <PUIText className={s.sessionContentName} color={theme.colors.base900} size={theme.typography.sizes.s}>
          {sessionName} {session.name}
        </PUIText>

        <PUIText className={s.sessionContentDate} color={theme.colors.base900} size={theme.typography.sizes.xxs}>
          {session.date}
        </PUIText>

        {notesScreen ? (
          <PUIText className={s.sessionContentNotes} color={theme.colors.base900} size={theme.typography.sizes.s}>
            <Icon icon="fileText" /> {session.notes.length} {text.notes}
          </PUIText>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

Session.propTypes = {
  to: PropTypes.string.isRequired,
  session: PropTypes.shape({
    number: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  status: PropTypes.bool.isRequired,
  notesScreen: PropTypes.bool,
};

Session.defaultProps = {
  children: null,
  notesScreen: false,
};

export { Session };
