export enum ReferenceQuestionType {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TELEPHONE = 'telephone',
  EMAIL = 'email',
  ACTIVITY = 'activity',
  ADDRESS = 'address',
  BIRTHDAY = 'birthday',
  COUNTRY = 'country',
  EDUCATION = 'education',
  EMERGENCY_CONTACT_PERSON = 'emergencyContactPerson',
  EMERGENCY_CONTACT_PHONE = 'emergencyContactPhone',
  GENDER = 'gender',
  GENDER_SPECIFICATION = 'genderSpecification',
  GENERAL_PRACTITIONER = 'generalPractitioner',
  GENERAL_PRACTITIONER_PHONE = 'generalPractitionerPhone',
  INSURANCE_COMPANY = 'insuranceCompany',
  INSURANCE_ID = 'insuranceId',
  MARITAL_STATUS = 'maritalStatus',
  MEDICATION = 'medication',
  NATIONALITY = 'nationality',
  PERSONAL_IDENTIFICATION_NUMBER = 'personalIdentificationNumber',
  POSTAL_CODE = 'postalCode',
  TOWNSHIP = 'townShip',
  HELP_REQUEST = 'helpRequest',
}
