export enum AnalyticsEvent {
  CreateRecord = 'record_creation', // done
  SignUp = 'sign_up', // + property {{role}}
  SignIn = 'sign_in', //  + property {{role}}
  StartTrajectory = 'start_trajectory', // done
  AddSession = 'add_session', // done, {{type: video/physical}}
  AddQuestionnaire = 'add_questionnaire', // done + property {{questionnaireAmount and questionnairesIds}}
  AddSync = 'add_sync', // done
  AddModule = 'add_module', //  done + property {{module ID}}
  AddExercise = 'add_exercise', // done
  ChangeTrajectoryType = 'change_trajectory_type', // + property {{ type }}
  ShareReport = 'share_report', // + property {{ questionnaireTitle }}
  AddDocument = 'add_document',
  UploadDocument = 'upload_document',
  ShareDocument = 'share_document',
  AddAffiliate = 'add_affiliate',
  InviteTherapist = 'invite_therapist',
  EndTrajectory = 'end_trajectory',
  ReactivateTrajectory = 'reactivate_trajectory',
  ArchiveRecord = 'archive_record',
  ReactivateRecord = 'reactivate_record',
  DeleteRecord = 'delete_record',
  InviteTherapistToOrg = 'invite_therapist_to_organization',
  CreateAddress = 'create_address',
  CreateContact = 'create_contact',
  CreateChat = 'create_chat',
  SendChatMessage = 'send_chat_message',
  CreateTemplate = 'create_template',
  CreateDocument = 'create_document',
  DownloadDocument = 'download_document',
  ClickHelp = 'click_help',
  ChangePortfolio = 'change_portfolio',
  SharePortfolio = 'share_portfolio',
  DownloadPortfolio = 'download_portfolio',
  UploadOrgLogo = 'upload_org_logo',
  UploadPicture = 'upload_picture',
  CreateCalendarCustomEvent = 'create_calendar_custom_event',
  CreateCalendarExportLink = 'create_calendar_export_link',
  ImportCalendar = 'import_calendar',
  ShareCalendar = 'share_calendar',
  CreateLabel = 'create_label',
  AssignLabel = 'assign_label',
  CreateTrajectoryNote = 'create_trajectory_note',
  CreatePersonalNote = 'create_personal_note',
  DownloadTrajectoryNotes = 'download_trajectory_note',
  DownloadPersonalNotes = 'download_personal_note',
  SelectSequence = 'select_sequence',
  DownloadSessionOverview = 'download_session_overview',
  EditTrajectoryNote = 'edit_trajectory_note',
  DeleteTrajectoryNote = 'delete_trajectory_note',
  EHealthBoxSearchEmpty = 'ehealthbox_search_empty',
  EHealthBoxHeader = 'ehealthbox_header',
  EHealthBoxInstruction = 'ehealthbox_instruction',
  EHealthBoxSearch = 'ehealthbox_search',
  EHealthBoxSearchSuccess = 'ehealthbox_search_success',
  AddIntake = 'add_intake',
  PublicIntakeSubmit = 'public_intake_submit',
  ReferIntake = 'refer_intake', // + property {{ destination }}
  AddIntakeToWaitingList = 'add_intake_to_waiting_list',
  DeleteIntake = 'delete_intake',
  CreateRecordFromIntake = 'create_record_from_intake',
  AddIntakeComment = 'add_intake_comment',
  EditIntakeComment = 'edit_intake_comment',
  DeleteIntakeComment = 'delete_intake_comment',
  OpenImportIntakeSidebar = 'open_import_intake_sidebar',
  DownloadImportTemplate = 'download_intakes_template',
  ImportCompletedIntakesFile = 'import_completed_intakes_file',
  ImportIntakesError = 'import_intakes_error',
  ImportIntakesSuccessful = 'import_intakes_successful',
  IntakeFormActive = 'intake_form_active',
  IntakeFormDisabled = 'intake_form_disabled',
  EditIntakeStopMessage = 'edit_intake_stop_message',
  EditIntakeForm = 'edit_intake_form',
  CopyIntakeFormLink = 'copy_intake_form_link',
  EditIntakeConfirmationEmail = 'edit_intake_confirmation_email',
}
