import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { TablePagination, TablePaginationProps } from '@panenco/ui';
import cx from 'classnames';
import theme from 'ui/utils/theme';

import { customStyles } from 'ui/components/select-pui/style';
import s from './table-pagination.module.scss';

const selectStyles = {
  ...customStyles('regular'),
  singleValue: () => ({
    color: theme.colors.secondary700,
  }),
};

export const PUITablePagination = ({ className, ...props }: TablePaginationProps) => {
  const { t } = useTranslation();

  return (
    <TablePagination
      locales={{
        itemsPerPage: t('itemsPerPage'),
        displayingItems: (rangeStart, rangeEnd, count) => t('displayingItems', { rangeStart, rangeEnd, count }),
        currentPage: (currentPage, allPages) => t('currentPage', { currentPage, allPages }),
      }}
      selectStyles={selectStyles}
      className={cx(s.root, className)}
      {...props}
    />
  );
};
