import { ComponentType } from 'react';

export const lazyRetry = (componentImport, name: string): Promise<{ default: ComponentType<any> }> =>
  new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(sessionStorage.getItem(`retry-${name}-refreshed`) || 'false');

    componentImport()
      .then((component) => {
        sessionStorage.setItem(`retry-${name}-refreshed`, 'false');
        resolve(component);
      })
      // eslint-disable-next-line consistent-return
      .catch((error) => {
        if (!hasRefreshed) {
          sessionStorage.setItem(`retry-${name}-refreshed`, 'true');
          return window.location.reload();
        }
        reject(error);
      });
  });
