import queryString from 'qs';
import { ArrayResponseType, BaseFetchListParams, TimelineEvent } from 'data/types';
import { SortDirection } from 'data/enums';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { intakesKeys } from 'data/utils/hookKeys';

import * as api from '../actions-query';

const defaultIntakeCommentsParams: IntakeCommentsParams = {
  sort: { createdAt: SortDirection.asc },
  limit: 12,
  offset: 0,
};

export const useIntakeTimeline = (intakeId: string, params = defaultIntakeCommentsParams) => {
  const query = queryString.stringify(params);
  return useQuery<ArrayResponseType<TimelineEvent>, AxiosError>(
    intakesKeys.timeline(intakeId),
    () => api.fetchIntakeTimeline(intakeId, query),
    {
      enabled: !!intakeId,
    },
  );
};
