import { api } from 'data';
import {
  ChatRoom,
  ChatMessage,
  CreateChatRoomFields,
  CreateChatMessageFields,
  ArrayResponseType,
  ChatRoomNotifications,
} from 'data/types';

export const fetchChatRoom = (chatId: string, userId: string): Promise<ChatRoom> =>
  api.get(`/chat/rooms/${chatId}/users/${userId}`);

export const fetchUserChatRooms = (userId: string, params: string): Promise<ArrayResponseType<ChatRoom>> =>
  api.get(`/chat/rooms/users/${userId}?${params}`);

export const fetchChatRoomsNotifications = (
  userId: string,
  query: string,
): Promise<ArrayResponseType<ChatRoomNotifications>> => api.get(`/chat/rooms/users/${userId}/notifications?${query}`);

export const createChatRoom = (body: CreateChatRoomFields): Promise<ChatRoom> => api.post(`/chat/rooms`, body);

export const deleteChatRoom = (roomId: string): Promise<void> => api.delete(`chat/rooms/${roomId}`);

export const fetchMessages = (chatId: string, params?: string): Promise<ArrayResponseType<ChatMessage>> =>
  api.get(`/chat/messages/rooms/${chatId}?${params}`);

export const createChatMessage = (chatId: string, body: CreateChatMessageFields): Promise<ChatMessage> =>
  api.post(`/chat/messages/rooms/${chatId}`, body);

export const fetchChatRoomFromRecord = (recordId: string, userId: string): Promise<ChatRoom> =>
  api.get(`/chat/rooms/records/${recordId}/users/${userId}`);
