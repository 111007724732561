import React from 'react';
import PropTypes from 'prop-types';
import { captureException } from 'app/utils/services/error-tracking';

export default class GlobalErrorBoundary extends React.Component {
  static componentDidCatch(error, errorInfo) {
    captureException(error, errorInfo);
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

GlobalErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};
