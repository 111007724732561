import React from 'react';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { toast } from 'ui';
import { capitalize, lowerFirst } from 'lodash-es';
import { AxiosError } from 'axios';

export const transformErrorKey = (key: string) =>
  key
    .split(/(?=[A-Z])/)
    .map((word, index) => (index === 0 ? capitalize(word) : lowerFirst(word)))
    .join(' ');

export const onRequestError = (error: AxiosError<{ message: string; violations?: { message: string }[] }>) => {
  const errorMessage = error.response?.data?.message || error.response?.data?.violations?.[0].message;

  if (errorMessage) {
    const errorsKeys: string[] = Object.keys(t('errors', { returnObjects: true }));

    const errorTranslation = errorsKeys.includes(errorMessage) ? (
      <Trans i18nKey={`errors.${errorMessage}`} />
    ) : (
      transformErrorKey(errorMessage)
    );

    toast.error(errorTranslation);
  } else {
    toast.error(<Trans i18nKey="errors.somethingWentWrong" />);
  }
};
