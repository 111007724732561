export enum EventUrlEnum {
  interaction = 'session',
  sync_interaction = 'sync',
  session = 'questionnaire',
  session_activity = 'session',
  module_task_activity = 'module',
  simple_task_activity = 'task',
  custom = 'custom',
  note = 'note',
  portfolioPublic = 'portfolioPublic',
}
