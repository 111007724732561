import { CalculationType } from 'data/enums';
import { GraphItem } from 'data/types';

export const data: GraphItem = {
  id: '2a5af563-f00c-11e9-b29e-0242ac120002',
  createdAt: '2019-10-16T11:53:38+00:00',
  dots: [
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac120002',
      createdAt: '',
      label: 'Dot from calc 1',
      y: 4,
      value: 7,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242c120002',
      createdAt: '',
      label: 'Dot from calc 2',
      y: 4,
      value: 2,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac123002',
      createdAt: '',
      label: 'Dot from calc 3',
      y: 4,
      value: 3,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-b29e-0242ac100sd02',
      createdAt: '',
      label: 'Dot from calc 4',
      value: 18,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-29e-0242ac1760002',
      createdAt: '',
      label: 'Dot from calc 5',
      y: 4,
      value: 4,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-29e-0242ac12002',
      createdAt: '',
      label: 'Dot from calc 6',
      y: 4,
      value: 6,
      size: 1,
    },
    {
      id: '2a5b1a4d-f00c-11e9-29e-0242ac140002',
      createdAt: '',
      label: 'Dot from calc 7',
      y: 4,
      value: 13,
      size: 1,
    },
  ],
  graph: {
    id: 'c218036d-f00b-11e9-b29e-0242ac120002',
    index: 0,
    orderIndex: 0,
    createdAt: '2019-10-16T11:53:38+00:00',
    backgroundNorms: [
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 1',
        id: '97943509-f00b-11e9-b29e-0242ac120002',
        upperLimit: 18.0,
        textColor: '#f44336',
        backgroundColor: '#03a9f4',
        graphBackgroundColor: '#fa0202',
        text: 'Text definition 1',
      },
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 2',
        id: '97943e3c-f00b-11e9-b29e-0242ac120002',
        upperLimit: 12.0,
        textColor: '#fbc02d',
        backgroundColor: '#0097a7',
        graphBackgroundColor: '#fa9b02',
        text: 'Text',
      },
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 2',
        id: '97943e3c-f00b-11e9-b29e-0242ac020002',
        upperLimit: 4.0,
        textColor: '#fac02d',
        backgroundColor: '#0397a7',
        graphBackgroundColor: '#f6fa02',
        text: 'Text',
      },
      {
        createdAt: '2019-10-16T11:53:38+00:00',
        name: 'Definition 3',
        id: '979446c5-f00b-11e9-b29e-0242ac120002',
        upperLimit: 1.0,
        textColor: '#ffcdd2',
        backgroundColor: '#02fa1f',
        graphBackgroundColor: '#02fa1f',
        text: 'Text',
      },
    ],
    type: {
      id: 'scores_graph',
      name: 'scores_graph',
      createdAt: '2019-10-16T11:51:55+00:00',
    },
    calculations: [
      {
        id: '59b0c8ac-f00b-11e9-b29e-0242ac120002',
        createdAt: '',
        name: 'Calculation',
        type: CalculationType.EMPTY,
      },
    ],
    questions: [1.0, 2.0, 3.0, 4.0, 5.0],
    intervalScale: '2',
    minScale: '0',
    maxScale: '20',
    titleAbove: 'Title above the graph',
    viewX: true,
    viewY: false,
    xLabel: 'Calculation',
    yLabel: 'Representation',
  },
  type: 'scores_graph',
};
