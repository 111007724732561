import {
  ChatRoom,
  CreateChatRoomFields,
  ChatMessage,
  CreateChatMessageFields,
  ArrayResponseType,
  BaseFetchListWithSearch,
  ChatRoomNotifications,
  BaseFetchListParams,
} from 'data/types';
import { chatKeys } from 'data/utils/hookKeys';
import {
  InfiniteData,
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import QueryString from 'qs';
import { getStoredUserId } from 'data/utils/userStorage';
import { ChatRoomsSortNames, SortDirection } from 'data/enums';
import { getNextPageParam } from 'data/utils/getNextPageParam';
import { fetchLimit } from 'app/constants/app';
import { v4 as uuid } from 'uuid';
import { AxiosError } from 'axios';

import * as api from '../actions-query';

export interface ChatRoomsFetchParams extends BaseFetchListWithSearch {
  sort?: {
    [key in ChatRoomsSortNames]: SortDirection;
  };
}

const defaultChatRoomsFetchParams: ChatRoomsFetchParams = {
  limit: 0,
  offset: 0,
  sort: {
    [ChatRoomsSortNames.updatedAt]: SortDirection.desc,
  },
};

export const useInfiniteChatRooms = (
  params = defaultChatRoomsFetchParams,
): UseInfiniteQueryResult<ArrayResponseType<ChatRoom>, AxiosError> => {
  const perPage = 30;

  const userId = getStoredUserId();
  const defaultQuery = QueryString.stringify(params);

  return useInfiniteQuery(
    chatKeys.list(defaultQuery),
    ({ pageParam = 0 }) => {
      // we have to override offset and limit for infinite query
      const query = QueryString.stringify({ ...params, offset: pageParam, limit: perPage });

      return api.fetchUserChatRooms(userId, query);
    },
    {
      getNextPageParam: (page, allPages) => getNextPageParam<ChatRoom>(page, allPages, perPage),
    },
  );
};

const defaultChatRoomNotificationsParams: BaseFetchListParams = {
  limit: fetchLimit,
  offset: 0,
};

export const useChatRoomsNotifications = (
  enabled = true,
): UseQueryResult<ArrayResponseType<ChatRoomNotifications>, AxiosError> => {
  const userId = getStoredUserId();
  const query = QueryString.stringify(defaultChatRoomNotificationsParams);

  return useQuery<ArrayResponseType<ChatRoomNotifications>, AxiosError>(
    chatKeys.notifications(),
    () => api.fetchChatRoomsNotifications(userId, query),
    { enabled },
  );
};

export const useChatRoom = (chatId: string) => {
  const userId = getStoredUserId();

  return useQuery<ChatRoom, AxiosError>(chatKeys.detail(chatId), () => api.fetchChatRoom(chatId, userId));
};

export const useCreateChatRoom = (): UseMutationResult<
  ChatRoom,
  AxiosError,
  { body: CreateChatRoomFields },
  unknown
> => {
  const userId = getStoredUserId();
  const queryClient = useQueryClient();

  return useMutation(
    ({ body }) => {
      const isChatWithYourself = body.users[0] === userId;
      return api.createChatRoom({ ...body, users: isChatWithYourself ? body.users : [...body.users, userId] });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(chatKeys.lists());
      },
    },
  );
};

export const useCreateChatMessage = (): UseMutationResult<
  ChatMessage,
  AxiosError,
  { chatId: string; fields: CreateChatMessageFields },
  unknown
> => {
  const queryClient = useQueryClient();
  const userId = getStoredUserId();

  return useMutation(({ chatId, fields }) => api.createChatMessage(chatId, fields), {
    onMutate: async ({ chatId, fields }) => {
      await queryClient.cancelQueries({ queryKey: chatKeys.messages(chatId) });

      const previousMessages = queryClient.getQueryData(chatKeys.messages(chatId));

      const newMessage: ChatMessage = {
        ...fields,
        createdAt: new Date().toISOString(),
        id: uuid(),
        sendBy: { id: userId, firstName: '', lastName: '', email: '', createdAt: '' },
        isLoading: true,
      };

      queryClient.setQueryData(chatKeys.messages(chatId), (old: InfiniteData<ArrayResponseType<ChatMessage>>) => {
        // we should modify only the first page of the infinite query
        const firstPage = {
          ...old.pages.slice(0, 1),
          total: old.pages[0].total + 1,
          items: [...old.pages[0].items, newMessage],
        };

        const restPages = old.pages.slice(1, old.pages.length - 1);

        return {
          ...old,
          pages: [firstPage, ...restPages],
        };
      });

      return { previousMessages };
    },
    onError: (_, { chatId }, context: { previousMessages: InfiniteData<ArrayResponseType<ChatMessage>> }) => {
      queryClient.setQueryData(chatKeys.messages(chatId), context.previousMessages);
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries(chatKeys.messages(variables.chatId));
      queryClient.invalidateQueries(chatKeys.lists());
    },
  });
};

export const useDeleteChatRoom = (): UseMutationResult<void, AxiosError, string, unknown> => {
  const queryClient = useQueryClient();

  return useMutation((roomId: string) => api.deleteChatRoom(roomId), {
    onSuccess: () => {
      queryClient.invalidateQueries(chatKeys.lists());
    },
  });
};

export const useChatRoomFromRecord = (recordId: string) => {
  const userId = getStoredUserId();

  return useQuery<ChatRoom, AxiosError>(chatKeys.record(recordId), () => api.fetchChatRoomFromRecord(recordId, userId));
};
